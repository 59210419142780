import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toogleGetEmailPromptModal } from "../../store/slices/transactionsSlice";
import CustomModal from "../customModal";
import { sendTransactionToEmail } from "../../helpers/apiUtilities";
import { toast } from "react-hot-toast";
import { CircleSpinner } from "../Loaders";

const GetEmailPromptModal= (props) => {
    // const {
    // } = props;

    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();

    const showModal = useSelector(state => state.transactions.showEmailPromptModal);


    const targetCheckoutDetails = useSelector(state => state.transactions.targetCheckoutDetails);
    const targetCheckoutId = targetCheckoutDetails?.checkout_id;


    useEffect(() => {
        if (showModal) {
            setEmailError("");
        }
    }, [showModal]);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    // const [sendEmailBtnHref, setSendEmailBtnHref] = useState("");

    const [showLoaderForSendingEmail, setShowLoaderForSendingEmail] = useState(false);


    const handleChangeEmailInputValue = (e) => {
        setEmail(e.target.value);
        setEmailError("");

        // const inpVal = e.target.value;
        // setEmail(inpVal);

        // const isValid = validateEmailAddress();

        // if (isValid) {
        //     setSendEmailBtnHref(`mailto:${inpVal}`);
        // }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setEmailError(t("enterValidEmailErr"));
        }
        else {
            setShowLoaderForSendingEmail(true);

            const response = await sendTransactionToEmail(targetCheckoutId, email);
            if (response?.status === 200) {
                // const result = response.data;
                // const result_data = result?.data;

                toast.success(
                    t("transactionHasBeenSentToYourEmail"),
                    {
                        duration: 4000
                    }
                );
            }
            else {
                const axios_err = response?.message;
                const err_message = response?.response?.data?.message || response?.response?.data?.errors?.error || axios_err || '';

                toast.error(
                    <div>
                        <h6 className="text-capitalize">
                            {t("sendingEmailFailed")}
                        </h6>
                        <div>{err_message}</div>
                    </div>,
                    {
                        duration: 8000
                    }
                );
            }

            setShowLoaderForSendingEmail(false);
            dispatch(toogleGetEmailPromptModal({
                showModal: false
            }));
        }
    };

    // const validateEmailAddress = (e) => {
    //     if (!email || !/\S+@\S+\.\S+/.test(email)) {
    //         setEmailError(t("enterValidEmailErr"));
    //         return false;
    //     }
    //     else {
    //         setEmailError("");
    //         return true;
    //     }
    // };

    const handleCloseModal = () => {
        dispatch(toogleGetEmailPromptModal({
            showModal: false
        }));
    };


    return (
        <CustomModal
            size="sm"
            show={showModal}
            onHide={handleCloseModal}
            centered
            className="custom-prompt-modal"
        >
            <Modal.Body>
                <div className="px-3">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-title mt-2 mb-3 fs-5">
                            {t("getEmailLabel")}
                        </div>
                        <div className="my-4">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="mail@sample.com"
                                value={email}
                                onInput={handleChangeEmailInputValue}
                            />
                            {emailError &&
                                <p>
                                    <small className="text-red">
                                        <em>
                                            {emailError}
                                        </em>
                                    </small>
                                </p>
                            }
                        </div>
                        <div className="mt-3 mb-1 text-end">
                            {/* <a
                                href={sendEmailBtnHref}
                                className={`btn btn-dark ${(!email || emailError) ? 'disabled' : ''}`}
                            >
                                {t("sendEmail")}
                            </a> */}
                            <button
                                className={`btn btn-dark ${(!email || emailError) ? 'disabled' : ''}`}
                            >
                                {showLoaderForSendingEmail && (
                                    <span className="icon me-2">
                                        <CircleSpinner
                                            spinnerColor="#fff"
                                            circleOpacity={0}
                                            thickness={5}
                                        />
                                    </span>
                                )}
                                {t("sendEmail")}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </CustomModal>
    )
}

export default GetEmailPromptModal;
