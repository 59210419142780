
let baseURL;
if (process.env.NODE_ENV === 'development') {
    baseURL = "https://dpt.pttp.me/api/v4/waiter";
}
else {
    baseURL = "https://www.digiairmenu.com/api/v4/waiter";
}

// const access_token = access_token_testServer;

export {
    baseURL,
};