import { useState } from 'react';

export default function useUserSettings() {
    const getUserSettings = () => {
        const userSettingsString = localStorage.getItem('userSettings');
        let userSettingsObj;
        try {
            userSettingsObj = JSON.parse(userSettingsString);
        } catch (error) {
            userSettingsObj = {};
        }
        return userSettingsObj;
    };

    const [userSettings, setUserSettings] = useState(getUserSettings());

    const saveUserSettings = details => {
        localStorage.setItem('userSettings', JSON.stringify(details));
        setUserSettings(details);
    };

    return {
        saveUserSettings,
        userSettings
    }
}