import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "./customModal";
import { setSelectAllPayablesState, setUseAppPrinterState, toggleShowSettingsModal } from "../store/slices/appGeneralSlice";
import useUserSettings from "./useUserSettings";

const ProfileSettingsModal= (props) => {
    // const {
    // } = props;

    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();

    const showModal = useSelector(state => state.appGeneral.showSettingsModal);


    const {
        userSettings: previousSavedSettings,
        saveUserSettings
    } = useUserSettings();


    const useAppPrinter = useSelector(state => state.appGeneral.useAppPrinter);
    const selectAllPayablesByDefault = useSelector(state => state.appGeneral.selectAllPayablesByDefault);


    const handleCloseModal = () => {
        dispatch(toggleShowSettingsModal({
            showModal: false
        }));
    };


    const changeUseAppPrinterState = (use_app_printer) => {
        dispatch(setUseAppPrinterState({
            useAppPrinter: use_app_printer
        }));

        const previous_saved_settings = previousSavedSettings || {};
        saveUserSettings({
            ...previous_saved_settings,
            generalOptions: {
                ...previous_saved_settings.generalOptions,
                useAppPrinter: use_app_printer
            }
        });
    };

    const changeSelectAllPayablesByDefaultState = (select_all_by_default) => {
        dispatch(setSelectAllPayablesState({
            selectAllPayablesByDefault: select_all_by_default
        }));

        const previous_saved_settings = previousSavedSettings || {};
        saveUserSettings({
            ...previous_saved_settings,
            generalOptions: {
                ...previous_saved_settings.generalOptions,
                selectAllPayablesByDefault: select_all_by_default
            }
        });
    };


    if (previousSavedSettings?.generalOptions?.useAppPrinter !== undefined && previousSavedSettings?.generalOptions?.useAppPrinter !== useAppPrinter) {
        changeUseAppPrinterState(previousSavedSettings.generalOptions.useAppPrinter);
    }
    if (previousSavedSettings?.generalOptions?.selectAllPayablesByDefault !== undefined && previousSavedSettings?.generalOptions?.selectAllPayablesByDefault !== selectAllPayablesByDefault) {
        changeSelectAllPayablesByDefaultState(previousSavedSettings.generalOptions.selectAllPayablesByDefault);
    }


    return (
        <CustomModal
            size="md"
            show={showModal}
            onHide={handleCloseModal}
            centered
            className="profile-settings-modal"
        >
            <Modal.Header>
                <span className="fs-5 fw-medium">
                    <span className="icon me-2 fs-3">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                    </span>
                    {t("settings")}
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="px-3">
                    <div className="settings-subgroup-title fw-lighter mb-3">
                        {t("generalSettings")}
                    </div>
                    <ul className="settings-subgroup-options px-2">
                        <li className="settings-subgroup-option">
                            <label
                                className="d-inline-block"
                                htmlFor="useAppPrinter_switch"
                            >
                                <div className="item-title">
                                    {/* <span className="icon"></span> */}
                                    {t("useAppConnectedPrinter")}
                                </div>
                                <div className="item-description">
                                    {t("youCanTurnOffThisOptionToSelectPrinterFromTheAvailableConnectedDevices")}
                                </div>
                            </label>
                            <span className="fs-4">
                                <Form.Check
                                    type="switch"
                                    id="useAppPrinter_switch"
                                    className="d-inline-block"
                                    label=""
                                    onChange={() => changeUseAppPrinterState(!useAppPrinter)}
                                    checked={useAppPrinter}
                                />
                            </span>
                        </li>
                        <li className="settings-subgroup-option">
                            <label
                                className="d-inline-block"
                                htmlFor="selectAllPayablesByDefault_switch"
                            >
                                <div className="item-title">
                                    {/* <span className="icon"></span> */}
                                    {t("selectAllPayablesByDefault")}
                                </div>
                                <div className="item-description">
                                    {t("selectAllPayablesByDefaultOnThePaymentPage")}
                                </div>
                            </label>
                            <span className="fs-4">
                                <Form.Check
                                    type="switch"
                                    id="selectAllPayablesByDefault_switch"
                                    className="d-inline-block"
                                    label=""
                                    onChange={() => changeSelectAllPayablesByDefaultState(!selectAllPayablesByDefault)}
                                    checked={selectAllPayablesByDefault}
                                />
                            </span>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
        </CustomModal>
    )
}

export default ProfileSettingsModal;
