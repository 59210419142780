import ContentLoader from "react-content-loader";
import useWindowSize from "../../helpers/useWindowSize";

const PaymentStatisticsPlaceholder = (props) => {
    // const {
    // } = props;

    const windowSize = useWindowSize();
    const {
        width: windowWidth
    } = windowSize || {};

    let windowSizeType;
    let plh_height = 876;

    if (windowWidth < 576) {
        windowSizeType = "small";
    }
    else if (windowWidth >= 576 && windowWidth < 1200) {
        windowSizeType = "medium";
    }
    else {
        windowSizeType = "large";
        plh_height = 512;
    }

    return (
        <div className="">
            <ContentLoader
                width={'100%'}
                height={plh_height}
                // viewBox="0 0 100 68"
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="9" y="34" rx="2" ry="2" width="75" height="22" />
                <rect x="9" y="70" rx="6" ry="6" width="calc(100% - 18px)" height="105" />


                {((windowSizeType === "small") && (
                    <>
                        <rect x="calc(50% - 60px)" y="207" rx="6" ry="6" width="120" height="26" />
                        <circle cx="33%" cy="348" r="45" />
                        <circle cx="67%" cy="348" r="65" />
                        <rect x="18%" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(18% + 78px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(18% + 156px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(18% + 234px)" y="480" rx="2" ry="2" width="66" height="20" />

                        <rect x="calc(50% - 60px)" y="542" rx="6" ry="6" width="120" height="26" />
                        <circle cx="33%" cy="685" r="45" />
                        <circle cx="67%" cy="685" r="65" />
                        <rect x="18%" y="818" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(18% + 78px)" y="818" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(18% + 156px)" y="818" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(18% + 234px)" y="818" rx="2" ry="2" width="66" height="20" />
                    </>
                )) || ((windowSizeType === "medium") && (
                    <>
                        <rect x="calc(50% - 60px)" y="207" rx="6" ry="6" width="120" height="26" />
                        <circle cx="calc(50% - 130px)" cy="348" r="45" />
                        <circle cx="calc(50% + 130px)" cy="348" r="65" />
                        <rect x="calc(50% - 140px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(50% - 62px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(50% + 16px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(50% + 94px)" y="480" rx="2" ry="2" width="66" height="20" />

                        <rect x="calc(50% - 60px)" y="542" rx="6" ry="6" width="120" height="26" />
                        <circle cx="calc(50% - 130px)" cy="685" r="45" />
                        <circle cx="calc(50% + 130px)" cy="685" r="65" />
                        <rect x="calc(50% - 140px)" y="818" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(50% - 62px)" y="818" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(50% + 16px)" y="818" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(50% + 94px)" y="818" rx="2" ry="2" width="66" height="20" />
                    </>
                )) || ((windowSizeType === "large") && (
                    <>
                        <rect x="calc(25% - 60px)" y="207" rx="6" ry="6" width="120" height="26" />
                        <circle cx="13%" cy="348" r="45" />
                        <circle cx="36%" cy="348" r="65" />
                        <rect x="13%" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(13% + 78px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(13% + 156px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(13% + 234px)" y="480" rx="2" ry="2" width="66" height="20" />

                        <rect x="calc(72% - 60px)" y="207" rx="6" ry="6" width="120" height="26" />
                        <circle cx="60%" cy="348" r="45" />
                        <circle cx="83%" cy="348" r="65" />
                        <rect x="60%" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(60% + 78px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(60% + 156px)" y="480" rx="2" ry="2" width="66" height="20" />
                        <rect x="calc(60% + 234px)" y="480" rx="2" ry="2" width="66" height="20" />
                    </>
                ))}
                

            </ContentLoader>
        </div>
    )
}

export default PaymentStatisticsPlaceholder;
