import { Modal } from "react-bootstrap";

import './SelectOptionsModal.scss';
import { useTranslation } from "react-i18next";
import CustomModal from "../customModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleSelectOptionsModal } from "../../store/slices/appGeneralSlice";


const SelectOptionsModal = (props) => {
    const {
        handleSelectOption,
        selectedValue,
    } = props;

    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();


    // const showModal = useSelector(state => state.paymentManagement.showOpenPaymentsModal);
    const showModal = useSelector(state => state.appGeneral.showSelectOptionsModal);

    const modalSelectTitle = useSelector(state => state.appGeneral.modalSelectTitle);
    const modalSelectOptionsList = useSelector(state => state.appGeneral.modalSelectOptionsList);


    const handleCloseModal = () => {
        dispatch(toggleSelectOptionsModal({
            showModal: false,
            selectTitle: "",
            selectOptions: []
        }));
    };

    const handleClickOnSelectOption = (optVal) => {
        if (typeof handleSelectOption === "function") {
            handleSelectOption(optVal);
        }
        handleCloseModal();
    }


    return (
        <CustomModal
            size="sm"
            // centered
            show={showModal}
            onHide={handleCloseModal}
            className="select-options-preview-modal"
        >
            <Modal.Body>
                <div className="select-title-bar">
                    <h4 className="select-title mb-0">
                        {modalSelectTitle}
                    </h4>
                </div>
                <ul className="options-list mb-2">
                    {modalSelectOptionsList?.length && modalSelectOptionsList.map((opt) => {
                        const {
                            label,
                            value,
                            // code
                        } = opt || {};

                        return (
                            <li
                                key={value}
                                className={`option-item ${selectedValue === value ? 'is-selected' : ''}`}
                                onClick={() => handleClickOnSelectOption(value)}
                            >
                                {label}
                            </li>
                        )
                    })}
                </ul>
            </Modal.Body>
        </CustomModal>
    )
}

export default SelectOptionsModal;

