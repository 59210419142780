import { createSlice } from "@reduxjs/toolkit";


const paymentManagementSlice = createSlice({
    name: 'paymentManagement',
    initialState: {
        partialPaymentIsPossible: true,
        referenceOrderDetails: {},
        selectedItemsForPayment: [],
        selectedCustomItemsForPayment: [],
        itemsForPaymentPrices: [],
        basePaymentAmount: 0,
        payableItemsAreLoading: false,
        showOpenPaymentsModal: false,
        openPaymentsData: null,
        posPaymentAlertReminderHasBeenSet: undefined,
        pendingPaymentCheckouts: {},
        outOfPendingStatusCheckouts: {},
    },
    reducers: {
        updateReferenceOrderDetails: (state, action) => {
            const {
                orderDetails = {},
            } = action.payload || {};

            state.referenceOrderDetails = orderDetails;
            state.payableItemsAreLoading = false;
        },
        changePaymentItemCount: (state, action) => {
            const {
                id,
                count,
            } = action.payload || {};

            if (count === 0) {
                state.selectedItemsForPayment = state.selectedItemsForPayment.filter(it => it.id !== id);
            }
            else if (count > 0) {
                const item = state.selectedItemsForPayment?.length && state.selectedItemsForPayment.find(it => it.id === id);

                if (!item) {
                    state.selectedItemsForPayment.push({
                        id: id,
                        count: count
                    });
                }
                else {
                    state.selectedItemsForPayment = state.selectedItemsForPayment.map(it => {
                        if (it.id === id) {
                            return {
                                ...it,
                                count: count
                            }
                        }
                        else {
                            return it;
                        }
                    });
                }
            }
        },
        changePaymentCustomItemCount: (state, action) => {
            const {
                id,
                count,
            } = action.payload || {};

            if (count === 0) {
                state.selectedCustomItemsForPayment = state.selectedCustomItemsForPayment.filter(it => it.id !== id);
            }
            else if (count > 0) {
                const item = state.selectedCustomItemsForPayment?.length && state.selectedCustomItemsForPayment.find(it => it.id === id);

                if (!item) {
                    state.selectedCustomItemsForPayment.push({
                        id: id,
                        count: count
                    });
                }
                else {
                    state.selectedCustomItemsForPayment = state.selectedCustomItemsForPayment.map(it => {
                        if (it.id === id) {
                            return {
                                ...it,
                                count: count
                            }
                        }
                        else {
                            return it;
                        }
                    });
                }
            }
        },
        updatePayableItemPrice: (state, action) => {
            const {
                id,
                totalPrice,
            } = action.payload || {};

            if (totalPrice === 0) {
                state.itemsForPaymentPrices = state.itemsForPaymentPrices.filter(it => it.id !== id);
            }
            else if (totalPrice > 0) {
                const item = state.itemsForPaymentPrices?.length && state.itemsForPaymentPrices.find(it => it.id === id);

                if (!item) {
                    state.itemsForPaymentPrices.push({
                        id: id,
                        totalPrice: totalPrice
                    });
                }
                else {
                    state.itemsForPaymentPrices = state.itemsForPaymentPrices.map(it => {
                        if (it.id === id) {
                            return {
                                ...it,
                                totalPrice: totalPrice
                            }
                        }
                        else {
                            return it;
                        }
                    });
                }
            }

            state.basePaymentAmount = state.itemsForPaymentPrices?.length && state.itemsForPaymentPrices?.reduce((acc, currVal) => acc + (+currVal?.totalPrice || 0), 0) || 0;
        },
        resetPayableItems: (state, action) => {
            state.selectedItemsForPayment = [];
            state.selectedCustomItemsForPayment = [];
            state.referenceOrderDetails = {};
            state.itemsForPaymentPrices = [];
            state.basePaymentAmount = 0;
        },
        activeLoaderForItems: (state, action) => {
            state.payableItemsAreLoading = true;
        },
        toggleOpenPaymentsModal: (state, action) => {
            const {
                showModal = !state.showOpenPaymentsModal
            } = action.payload || {};

            state.showOpenPaymentsModal = showModal;
        },
        changeOpenPaymentsData: (state, action) => {
            const {
                data
            } = action.payload || {};

            state.openPaymentsData = data;
        },
        toggleShowingPosPaymentAlertReminder: (state, action) => {
            const {
                setReminder,
            } = action.payload || {};

            state.posPaymentAlertReminderHasBeenSet = setReminder;
        },
        addToPendingPaymentCheckouts: (state, action) => {
            const {
                table_id,
                order_id,
                checkout_id
            } = action.payload || {};

            if (checkout_id) {
                state.pendingPaymentCheckouts = {
                    ...state.pendingPaymentCheckouts,
                    [checkout_id]: {
                        order_id,
                        table_id
                    }
                };
            }
        },
        AddToOutOfPendingStatusCheckouts: (state, action) => {
            const {
                table_id,
                order_id,
                checkout_id
            } = action.payload || {};

            if (checkout_id) {
                delete state.pendingPaymentCheckouts[checkout_id];

                state.outOfPendingStatusCheckouts = {
                    ...state.outOfPendingStatusCheckouts,
                    [checkout_id]: {
                        table_id,
                        order_id,
                    }
                };
            }
        },
        removeFromOutOfPendingStatusCheckouts: (state, action) => {
            const {
                checkout_id
            } = action.payload || {};

            if (checkout_id) {
                delete state.outOfPendingStatusCheckouts[checkout_id];
            }
        },
    }
});


export const {
    updateReferenceOrderDetails,
    changePaymentItemCount,
    changePaymentCustomItemCount,
    updatePayableItemPrice,
    resetPayableItems,
    activeLoaderForItems,
    toggleOpenPaymentsModal,
    changeOpenPaymentsData,
    toggleShowingPosPaymentAlertReminder,
    addToPendingPaymentCheckouts,
    AddToOutOfPendingStatusCheckouts,
    removeFromOutOfPendingStatusCheckouts,
} = paymentManagementSlice.actions;

export default paymentManagementSlice.reducer;
