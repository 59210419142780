import { useState } from 'react';

export default function useRestaurantDetails() {
    const getRestaurantDetails = () => {
        const restaurantDetailsString = localStorage.getItem('restaurantDetails');
        let restaurantDetailsObj;
        try {
            restaurantDetailsObj = JSON.parse(restaurantDetailsString);
        } catch (error) {
            restaurantDetailsObj = {};
        }
        return restaurantDetailsObj;
    };

    const [restaurantDetails, setRestaurantDetails] = useState(getRestaurantDetails());

    const saveRestaurantDetails = details => {
        localStorage.setItem('restaurantDetails', JSON.stringify(details));
        setRestaurantDetails(details);
    };

    return {
        setRestaurantDetails: saveRestaurantDetails,
        restaurantDetails
    }
}