import Select from 'react-select';
import './Header.scss';

import logoIcon from './../../components/images/app-icon-circle.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoaderForAddedOrdersBox, refreshDashboardData, selectOrder, activateLoaderForOrdersList, selectStep } from '../../store/slices/stepManagementSlice';
import { LANGUAGES } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { changeDemoModeState, setShowDemoModeBtnLoader, changeMobileDrawerMenuShowState, toggleSwapOnOrderAddedItemsBox, toggleShowSettingsModal } from '../../store/slices/appGeneralSlice';
import { PlusSquareIcon } from '../svgIcons/AllIcons';
import { changeDemoMode } from '../../helpers/apiUtilities';
import useRestaurantDetails from '../useRestaurantDetails';
import { CircleSpinner } from '../Loaders';
import { toggleOpenPaymentsModal } from '../../store/slices/paymentManagementSlice';
import { Dropdown, DropdownButton } from 'react-bootstrap';
// import useToken from '../../Routes/Login/useToken';


const Header = (props) => {
    const {
        handleNavigateToLoginPage,
    } = props;


    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();
    const currentPath = location.pathname;

    const isOnDashboardPage = currentPath === '/';
    const isOnPaymentPage = currentPath === '/payment';

    const [searchParams, setSearchParams] = useSearchParams();

    const { restaurantDetails, setRestaurantDetails } = useRestaurantDetails();


    const isDemoMode = useSelector(state => state.appGeneral.isDemoMode);
    const isMobileView = useSelector(state => state.appGeneral.isMobileView);

    const currentTableId = useSelector(state => state.stepManagement.currentTableId);
    const currentOrderId = useSelector(state => state.stepManagement.currentOrderId);
    const showOrderAddedItemsBoxInMobileView = useSelector(state => state.appGeneral.showOrderAddedItemsBoxInMobileView);

    const showDemoModeBtnLoader = useSelector(state => state.appGeneral.showDemoModeBtnLoader);

    const initialLanguageCode = localStorage.getItem("langCode") || 'en';


    useEffect(() => {
        i18n.changeLanguage(initialLanguageCode);
        
        dispatch(changeDemoModeState({
            isDemoMode: !!restaurantDetails?.isDemoMode,
        }));
    }, []);


    const handleUserLogout = (e) => {
        e.preventDefault();
        handleNavigateToLoginPage();

        setRestaurantDetails({});
    };

    const onChangeLang = (newOpt, actionMeta) => {
        const lang_code = newOpt?.value;
        localStorage.setItem('langCode', lang_code);
        i18n.changeLanguage(lang_code);

        window.location.reload(false);
    };

    const HandleGoBackToTheDahboardPage = () => {
        // navigate(-1);
        const currentSearchParams_tableId = Number(searchParams.get('tableId'));
        const tableId = currentSearchParams_tableId || currentTableId;
        const currentSearchParams_orderId = Number(searchParams.get('orderId'));
        const orderId = currentSearchParams_orderId || currentOrderId;

        navigate(`/?tableId=${tableId}&orderId=${orderId}`);

        dispatch(refreshDashboardData());
    };

    const handleBackToTablesList = () => {
        navigate(`/`);

        dispatch(selectStep({
            targetStepId: "tablesListShowStep",
            tablesList: null,
            tableId: null,
            tableTitle: "",
            orderId: null,
            currentOrderDetails: {}
        }));
        // dispatch(refreshOrdersListBox());
    };

    const handleUnselectOrder = () => {
        dispatch(selectOrder({
            orderId: null,
        }));

        dispatch(toggleLoaderForAddedOrdersBox({
            isLoading: false
        }));

        setSearchParams({ ...searchParams, tableId: currentTableId });
    };

    const handleBackToOrdersPreviewBox = () => {
        dispatch(toggleSwapOnOrderAddedItemsBox({
            swapToOrderAddedItemsBox: false
        }));
        handleUnselectOrder();
    };

    const handleToggleDemoMode = async () => {
        setSearchParams({ ...searchParams, tableId: currentTableId });
        
        dispatch(selectOrder({
            orderId: null,
        }));
        dispatch(activateLoaderForOrdersList());
        // dispatch(setTableOrdersListData({
        //     tableOrdersList: [],
        //     orderId: null,
        // }));

        dispatch(setShowDemoModeBtnLoader({
            show: true
        }));

        const response = await changeDemoMode(!isDemoMode);
        if (response?.status === 200) {
            const result = response.data;
            const vendor_det = result?.data;

            const is_demoMode = vendor_det?.is_demo_mode;
            dispatch(changeDemoModeState({
                isDemoMode: is_demoMode,
            }));

            setRestaurantDetails({
                ...restaurantDetails,
                isDemoMode: is_demoMode,
            });

            dispatch(setShowDemoModeBtnLoader({
                show: false
            }));

            dispatch(refreshDashboardData());
        }
        // else {
        //     // 
        // }
    };

    const handleShowOpenPeysModal = async () => {
        dispatch(toggleOpenPaymentsModal({
            showModal: true,
        }));
    };

    const handleShowMobileDrawerMenu = () => {
        dispatch(changeMobileDrawerMenuShowState({
            show: true
        }));
    };

    const handleOpenSettingsModal = () => {
        dispatch(toggleShowSettingsModal({
            showModal: true
        }));
    };


    return (
        <header className="header-navbar">
            <div className="navbar navbar-inner">
                <div className="navbar-menu d-flex flex-grow-1">
                    {!isOnDashboardPage ? (
                        <div className="d-flex align-items-center text-left float-left me-3">
                            <button
                                className="btn icon-btn btn-outline-secondary rounded-circle"
                                onClick={HandleGoBackToTheDahboardPage}
                            >
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" fillRule="evenodd"><path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/><path d="M8.707.293a1 1 0 0 1 0 1.414L2.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z"/></svg>
                                </span>
                            </button>
                        </div>
                    ) : (isMobileView && showOrderAddedItemsBoxInMobileView) && (
                        <button
                            className="btn btn-outline-secondary w-auto me-2 d-flex align-items-center px-3 py-0 me-3"
                            onClick={handleBackToOrdersPreviewBox}
                        >
                            <span className="icon btn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" fillRule="evenodd"><path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/><path d="M8.707.293a1 1 0 0 1 0 1.414L2.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z"/></svg>
                            </span>
                            <span className="ms-2">
                                {t("ordersList")}
                            </span>
                        </button>
                    )}

                    {isOnPaymentPage && (
                        <span className="me-2">
                            <button
                                className="back-to-table-list-btn btn btn-outline-secondary fw-medium ps-3"
                                onClick={handleBackToTablesList}
                            >
                                <span className="icon btn-icon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" fillRule="evenodd"><path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/><path d="M8.707.293a1 1 0 0 1 0 1.414L2.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z"/></svg>
                                </span>
                                <span className="btn-text">
                                    {t("tablesList")}
                                </span>
                            </button>
                        </span>
                    )}

                    {(isOnDashboardPage && true) && (
                        <span className="me-2">
                            <button
                                className={`dm-switch-btn p-0 border-0 ${isDemoMode ? 'active' : ''} ${showDemoModeBtnLoader ? 'show-loader' : ''}`}
                                onClick={handleToggleDemoMode}
                                disabled={showDemoModeBtnLoader}
                            >
                                {showDemoModeBtnLoader && (
                                    <span className="icon loader-icon">
                                        <CircleSpinner
                                            circleOpacity={0}
                                            spinnerColor="#fff"
                                            thickness={5}
                                        />
                                    </span>
                                )}
                                <span className="btn-text">
                                    D
                                </span>
                            </button>
                        </span>
                    )}

                    <span>
                        <button
                            className="pwa-install-prompt-btn btn btn-flat"
                            title={t("addDAMToHomeScreen")}
                            style={{
                                display: 'none'
                            }}
                        >
                            <span className="icon">
                                <PlusSquareIcon />
                            </span>
                            <span className="btn-text-wrapper">
                                <span className="btn-text">
                                    {t("installDAM")}
                                </span>
                            </span>
                        </button>
                    </span>

                    <div className="flex-grow-1 text-md-right clearfix d-flex justify-content-end">
                        {!isMobileView ? (
                            <>
                                <button
                                    className="btn btn-outline-success fw-medium me-3 text-capitalize px-1 px-sm-3"
                                    onClick={handleShowOpenPeysModal}
                                >
                                    {t("openPayments")}
                                </button>

                                <div className="navbar-language-select select2-elem-wrapper me-2 me-sm-4">
                                    <Select
                                        id="change_language_select"
                                        // title="language"
                                        // name="language"
                                        classNamePrefix="select"
                                        // className={`basic-single custom-select__menu-scrollbar`}
                                        // className="form-control"
                                        // styles={selectStyles}
                                        options={LANGUAGES}
                                        defaultValue={LANGUAGES[0]}
                                        onChange={onChangeLang}
                                        value={LANGUAGES.find(lang => lang.value === initialLanguageCode) || ''}
                                        // formatOptionLabel={handleFormatOptionLabel}
                                        // menuPortalTarget={document.body}
                                        // isDisabled={false}
                                        // isLoading={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        menuPlacement="bottom"
                                    />
                                </div>


                                <DropdownButton
                                    // id="userNavbarDropdownMenu"
                                    as="div"
                                    align="end"
                                    className="user-navbar-dropdown"
                                    title={
                                        <>
                                            <img
                                                src={logoIcon}
                                                className="header-profile-img"
                                                width="40"
                                                height="40"
                                                alt="digiAirMenu logo"
                                            />
                                            <span className="user-name">
                                                <span className="user-name-text text-capitalize">
                                                    {/* {t("logout")} */}
                                                    {/* {restaurantDetails?.restaurant_name} */}
                                                    {restaurantDetails?.name}
                                                </span>
                                            </span>
                                        </>
                                    }
                                >
                                    <Dropdown.ItemText
                                        className="d-flex align-items-center"
                                    >
                                        <span className="avatar-img">
                                            <img
                                                src={restaurantDetails?.restaurant_logo || logoIcon}
                                                width="40"
                                                height="40"
                                                alt="digiAirMenu logo"
                                            />
                                        </span>
                                        <div className="user-general-info d-inline-block">
                                            <div className="user-name text-capitalize">
                                                {restaurantDetails?.name}
                                            </div>
                                            <div className="user-id">
                                                {restaurantDetails?.user_name}
                                            </div>
                                        </div>
                                    </Dropdown.ItemText>
                                    <Dropdown.Divider />
                                    {/* <Dropdown.Item
                                        className="text-capitalize"
                                    >
                                        <span className="icon">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                                        </span>
                                        <span>
                                            {t("profile")}
                                        </span>
                                    </Dropdown.Item> */}
                                    <Dropdown.Item
                                        className="text-capitalize"
                                        onClick={handleOpenSettingsModal}
                                    >
                                        <span className="icon">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                                        </span>
                                        <span>
                                            {t("settings")}
                                        </span>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        className="text-capitalize"
                                        onClick={handleUserLogout}
                                    >
                                        <span className="icon">
                                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                        </span>
                                        <span>
                                            {/* {t("logout")} */}
                                            {t("signOut")}
                                        </span>
                                    </Dropdown.Item>
                                </DropdownButton>
                            </>
                        ) : (
                            <>
                                <button
                                    className="btn mobile-hamburger-btn px-3"
                                    onClick={handleShowMobileDrawerMenu}
                                >
                                    <span className="icon">
                                        <svg height="22pt" width="22pt" viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/></svg>
                                    </span>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
