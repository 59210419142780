import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import './App.scss';

import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../Routes/Login';

import useToken from './Authentication/useToken';
import Header from './Layouts/Header';
import GetEmailPromptModal from './Transaction/GetEmailPromptModal';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileModeState } from '../store/slices/appGeneralSlice';
// import NotFound from '../Routes/NotFound';
import NavigateSetter from '../helpers/NavigateSetter';
import MobileDrawerMenu from './Layouts/MobileDrawerMenu';

import PaymentStatisticsModal from '../components/Payment/PaymentStatisticsModal';

import ReconnectingWebSocket from 'reconnecting-websocket';
// import { io } from "socket.io-client";
import useRestaurantDetails from './useRestaurantDetails';
import { refreshDashboardData, refreshOrdersListBox } from '../store/slices/stepManagementSlice';
import { AddToOutOfPendingStatusCheckouts } from '../store/slices/paymentManagementSlice';
import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';
import { changeCheckoutDetailsInTransactionData } from '../store/slices/transactionsSlice';
import ProfileSettingsModal from './ProfileSettingsModal';

// const Login = lazy(() => import('./Authentication/Login'));
const Dashboard = lazy(() => import('../Routes/Dashboard'));
const Payment = lazy(() => import('../Routes/Payment'));


const EmptyPageBox = () => (
    <div className="card cash-register-card flex-grow-1">
        <div className="card-main-content d-flex flex-column h-100 pb-2"></div>
        <div className="multi-step-process-box"></div>
    </div>
)

const LayoutAndHeader = (props) => {
    const {
        children,
        handleNavigateToLoginPage,
    } = props;

    const dispatch = useDispatch();

    const { restaurantDetails } = useRestaurantDetails();
    const {
        ws_url
    } = restaurantDetails || {};

    const pendingPaymentCheckouts = useSelector(state => state.paymentManagement.pendingPaymentCheckouts);


    useEffect(() => {  
        let conectionHasError;
        let firstPingHasSent = false;
        let internetConnectionStatus = "offline";
        let anIntervalWasCreatedForTestInternetConnection;

        if (ws_url) {            
            // const socket = new WebSocket(ws_url);
            const socket = new ReconnectingWebSocket(ws_url);

            const handleOnWebSocketOpen = (event) => {
                // console.log('WebSocket is now open');

                // if (conectionHasError) {
                //     console.log("Connection re-established.");
                // }

                if (!firstPingHasSent) {
                    socket.send(JSON.stringify({
                        "command": "ping"
                    }));
                    firstPingHasSent = true;
                }

                conectionHasError = false;

                internetConnectionStatus = "online";

                handleShowOnlineOrOfflineStatus("", "online");

                if (!anIntervalWasCreatedForTestInternetConnection) {
                    anIntervalWasCreatedForTestInternetConnection = true;

                    setInterval(() => {
                        if (internetConnectionStatus !== "online" || socket.readyState == "3" || !navigator?.onLine) {
                            handleShowOnlineOrOfflineStatus("", "offline");

                            setTimeout(() => {
                                // console.log("ready state: ", socket.readyState);
                                socket.send(JSON.stringify({
                                    "command": "ping"
                                }));
                            }, 100);
                            
                            setTimeout(() => {
                                if (internetConnectionStatus !== "online" || socket.readyState == "3" || !navigator?.onLine) {
                                    // If the network will change, the open command will not be called automatically
                                    // socket._disconnect();
                                    socket._connect();
                                    // socket.close();
                                    // socket.open();
                                    // console.log("WS re-opening");
                                }
                            }, 20000);
                        }
                        else {
                            handleShowOnlineOrOfflineStatus("", "online");
                            // console.log("WS is steal open, ready state: ", socket.readyState);
                            socket.send(JSON.stringify({
                                "command": "ping"
                            }));
                        }

                        internetConnectionStatus = "";
                    }, 10000);
                }
            };

            const handleOnWebSocketMessage = (event) => {
                let mess_data = {};
                try {
                    mess_data = JSON.parse(event.data);
                } catch (error) {}

                // console.log('Received message:', mess_data);

                if (mess_data.type === "ping") {
                    if (mess_data.data === "pong") {
                        internetConnectionStatus = "online";
                    }
                }
                else if (mess_data?.type === "success_viva_wallet_transaction") {
                    const {
                        // demo_mode,
                        data,
                    } = mess_data;

                    const {
                        // viva_wallet_transaction_id,
                        // payment_status,
                        // paid_amount,
                        // tip_amount,
                        // isv_amount,
                        // created_at,
                        order_pay,
                    } = data || {};

                    const {
                        checkout_id,
                        // waiter_id,
                        // total_price,
                        // currency_id,
                        // payment_type,
                        // payment_status: payment_status_of_order_pay,
                        // updated_at,
                        // receipt_temp_link
                    } = order_pay || {};


                    const tableId_of_checkout = pendingPaymentCheckouts && pendingPaymentCheckouts[checkout_id]?.table_id;
                    const orderId_of_checkout = pendingPaymentCheckouts && pendingPaymentCheckouts[checkout_id]?.order_id;

                    dispatch(AddToOutOfPendingStatusCheckouts({
                        table_id: tableId_of_checkout,
                        order_id: orderId_of_checkout,
                        checkout_id,
                    }));

                    dispatch(changeCheckoutDetailsInTransactionData({
                        new_checkoutDetails: mess_data.data
                    }));

                    if (tableId_of_checkout && orderId_of_checkout) {
                        toast.success((tst) => (
                            <div className="toast-content-with-inline-link">
                                <Trans i18nKey="checkoutHasPaidByPosInJSX">
                                    The <strong>Checkout</strong> with id <strong>{{checkout_id}}</strong> from <Link className="" to={{pathname: "/", search: `?tableId=${tableId_of_checkout}&orderId=${orderId_of_checkout}`}}>order {{orderId_of_checkout}}</Link> has successfully paid through POS.
                                </Trans>
                                <button className="btn dismiss-btn" onClick={() => toast.dismiss(tst.id)}>
                                    &times;
                                </button>
                            </div>
                        ), {
                            duration: 8000
                        });
                    }
                    else {
                        toast.success((tst) => (
                            <div className="toast-content-with-inline-link">
                                <Trans i18nKey="checkoutHasPaidByPosInJSXSimpleText">
                                    The <strong>Checkout</strong> with id <strong>{{checkout_id}}</strong> has successfully paid through POS.
                                </Trans>
                                <button className="btn dismiss-btn" onClick={() => toast.dismiss(tst.id)}>
                                    &times;
                                </button>
                            </div>
                        ), {
                            duration: 8000
                        });
                    }

                    dispatch(refreshOrdersListBox());
                }
            };

            const handleOnWebSocketClose = (event) => {
                if (event.wasClean) {
                    // console.log(`[clean close] WebSocket connection closed cleanly, code=${event.code} reason=${event.reason}`);

                    // console.log("Connection closed. Reconnecting...");

                    conectionHasError = true;
                }
                else {
                    // e.g. server process killed or network down
                    // event.code is usually 1006 in this case
                    // console.warn("[not clean close] WebSocket connection died");

                    // console.log("Connection lost. Reconnecting...");

                    conectionHasError = true;
                }
            };

            const handleOnWebSocketError = (event) => {
                // console.log("[error] WebSocket connection error");

                // console.log("Connection issue. Reconnecting...");

                conectionHasError = true;
            };

            socket.addEventListener('open', handleOnWebSocketOpen);
            socket.addEventListener('message', handleOnWebSocketMessage);
            socket.addEventListener('close', handleOnWebSocketClose);
            socket.addEventListener('error', handleOnWebSocketError);

            window.addEventListener('online', handleShowOnlineOrOfflineStatus);
            window.addEventListener('offline', handleShowOnlineOrOfflineStatus);

            return () => {
                // socket.disconnect();
                socket._disconnect();

                socket.removeEventListener('open', handleOnWebSocketOpen);
                socket.removeEventListener('message', handleOnWebSocketMessage);
                socket.removeEventListener('close', handleOnWebSocketClose);
                socket.removeEventListener('error', handleOnWebSocketError);

                window.removeEventListener('online', handleShowOnlineOrOfflineStatus);
                window.removeEventListener('offline', handleShowOnlineOrOfflineStatus);
            };
        }
    }, []);


    const handleShowOnlineOrOfflineStatus = (event, status) => {
        const isOnline = event?.type === "online" || status === "online";
        const isOffline = event?.type === "offline" || status === "offline";

        // if (isOffline) {
        //     console.log("Your Internet connection lost. Please check and retry.");
        // }
        // if (isOnline) {
        //     console.log("You Have A Connection On WebSocket !!!");
        // }
    };


    const isMobileView = useSelector(state => state.appGeneral.isMobileView);

    const showOpenPaymentsModal = useSelector(state => state.paymentManagement.showOpenPaymentsModal);

    return (
        <div className="page-wrapper">
            <Header
                handleNavigateToLoginPage={handleNavigateToLoginPage}
            />
            {isMobileView && (
                <MobileDrawerMenu
                    handleNavigateToLoginPage={handleNavigateToLoginPage}
                />
            )}
            <div id="main_content" className="main-content-wrap d-flex">
                <div className="container-fluid container-limited mb-1 d-flex flex-column">
                    {
                        children
                    }
                </div>
            </div>

            {showOpenPaymentsModal && (
                <PaymentStatisticsModal />
            )}
        </div>
    )
};

const AuthenticationHandler = (props) => {
    const {
        children,
        ...restProps
    } = props;

    const { token, setToken } = useToken();

    const handleNavigateToLoginPage = () => {
        setToken("");
    };

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            handleNavigateToLoginPage,
            // setToken,
            ...restProps
        })
    );

    return (
        token ? (
            childrenWithProps
        ) : (
            <Login
                setToken={setToken}
            />
        )
    );
}


function App() {
    const { token, setToken } = useToken();

    const dispatch = useDispatch();

    // const [searchParams, setSearchParams] = useSearchParams();
    // const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const currentOrderId = useSelector(state => state.stepManagement.currentOrderId);
    const orderId = searchParams.get('orderId') || currentOrderId;


    useEffect(() => {
        toggleMobileMode();
        window.addEventListener('resize', handleToggleMobileMode)
    }, []);


    // const [waiterIsLogedIn, setWaiterIsLogedIn] = useState(false);


    const toggleMobileMode = () => {
        const win_width = window.innerWidth;

        dispatch(toggleMobileModeState({
            isMobileView: win_width < 768
        }));
    };

    const debouncedMobileModeHandler = useMemo(
        () => debounce(toggleMobileMode, 100)
    , []);

    const handleToggleMobileMode = () => {
        debouncedMobileModeHandler();
    };


    // if(!token) {
    //     return (
    //         <Login
    //             setToken={setToken}
    //             // setWaiterIsLogedIn={setWaiterIsLogedIn}
    //         />
    //     )
    // }


    return (
        <BrowserRouter>
            <NavigateSetter />

            <Routes>
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<EmptyPageBox />}>
                            <AuthenticationHandler>
                                <LayoutAndHeader>
                                    <Dashboard />
                                </LayoutAndHeader>
                            </AuthenticationHandler>
                        </Suspense>
                    }
                />
                <Route
                    path="/payment"
                    element={
                        <Suspense fallback={<EmptyPageBox />}>
                            {orderId ? (
                                <AuthenticationHandler>
                                    <LayoutAndHeader>
                                        <Payment />
                                    </LayoutAndHeader>
                                </AuthenticationHandler>
                            ) : (
                                <Navigate to="/" />
                            )}
                        </Suspense>
                    }
                />
                <Route
                    path="/login"
                    element={
                        // <Suspense fallback={<EmptyPageBox />}>
                            <AuthenticationHandler>
                                <Login
                                    setToken={setToken}
                                />
                            </AuthenticationHandler>
                        // </Suspense>
                    }
                />
                {/* <Route
                    path="/404"
                    element={
                        <NotFound />
                    }
                /> */}
                {/* <Route path="*" element={<Navigate to="/404" />} /> */}

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            <GetEmailPromptModal />

            <ProfileSettingsModal />
        </BrowserRouter>
    )
}

export default App;
