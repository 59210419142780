
export const CircleSpinner = (props) => {
    const {
        circleOpacity = 0.5,
        // circleColor = "#4fa94d",
        spinnerColor = "#4fa94d",
        thickness = 2,
    } = props;
    
    return (
        <svg width="80" height="80" viewBox={`${-20 - +thickness} ${-20 - +thickness} ${42 + (2 * thickness)} ${42 + (2 * thickness)}`} xmlns="http://www.w3.org/2000/svg" stroke={spinnerColor}><g fill="none" fillRule="evenodd"><g transform="translate(1 1)" strokeWidth={thickness}><circle strokeOpacity={circleOpacity} cx="0" cy="0" r="20" stroke={spinnerColor} strokeWidth={thickness}></circle><path d="M20 0c0-9.94-8.06-20-20-20"><animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform></path></g></g></svg>
    );
}
