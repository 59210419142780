import { createSlice } from "@reduxjs/toolkit";
import allStepsInfo from "../../components/Dashboard/stepsBox/allStepsInfo";


const stepManagementSlice = createSlice({
    name: 'stepManagement',
    initialState: {
        currentStepId: allStepsInfo[0]?.id,
        stepsData: [allStepsInfo[0]],
        tablesList: null,
        currentTableId: '',
        currentTableTitle: '',
        currentOrderId: null,
        currentOrderDetails: null,
        currentOrderDetailsErrorText: '',
        currentTableOrdersList: null,
        currentTableOlderOrdersList: [],
        currentTableOrdersListForPreview: null,
        currentTableOlderOrdersListForPreview: [],
        totalPriceOfOrderAddedItems: 0,
        tablesListItemsAreLoading: false,
        ordersListItemsAreLoading: false,
        olderOrdersListItemsAreLoading: false,
        loadingForCreatingNewOrder: false,
        addedOrdersBoxIsLoading: false,
        availableItemsAreLoading: false,
        availableItemsList: null,
        selectedCategoryId: '',
        itemPreviewBoxIsLoading: false,
        previewItemDetails: null,
        previewCustomItemStepsData: null,
        editingCustomItemType: undefined,
        initialItemCustomizationForEditing: null,
        previewItemDataForEditing: null,
        currency: '',
        showSendTokitchenModal: false,
        showChangeTableModal: false,
        itemsForSendToKitchen: [],
        customItemsForSendToKitchen: [],
        allItemsAreSelectedForSendingToKitchen: false,
        refreshDashboardDataFlag: undefined,
        refreshOrdersListBoxFlag: undefined,
        showOlderOrders: false,
        // showCanceledOrders: false,
        showDeliveredOrders: true,
        showPaidOrders: true,
        showTransactionsHistoryModal: false,
    },
    reducers: {
        selectStep: (state, action) => {
            const {
                targetStepId = state.currentStepId,
                tablesList = state.tablesList,
                tableId = state.currentTableId,
                tableTitle = state.currentTableTitle,
                orderId = state.currentOrderId,
                currentOrderDetails = state.currentOrderDetails,
                errorText = "",
                availableItemsList = state.availableItemsList,
                previewItemDetails = state.previewItemDetails,
                previewCustomItemStepsData = state.previewCustomItemStepsData,
            } = action.payload || {};

            const targetStepData = allStepsInfo.find(stp => stp.id === targetStepId);
            const targetStepIndex = allStepsInfo.indexOf(targetStepData);
            const new_stepsData = allStepsInfo.filter((stp, index) => {
                return index <= targetStepIndex
            });

            state.currentStepId = targetStepData?.id || "";
            state.tablesList = tablesList;
            state.currentTableId = tableId;
            state.currentTableTitle = tableTitle;
            state.currentOrderId = orderId;
            state.currentOrderDetails = currentOrderDetails;
            state.currentOrderDetailsErrorText = errorText;
            state.availableItemsList = availableItemsList;
            state.previewItemDetails = previewItemDetails;
            state.previewCustomItemStepsData = previewCustomItemStepsData;
            state.stepsData = new_stepsData;
            if (tablesList) {
                state.tablesListItemsAreLoading = false;
            }
            if (availableItemsList) {
                state.availableItemsAreLoading = false;
            }
            if (previewItemDetails || previewCustomItemStepsData) {
                state.itemPreviewBoxIsLoading = false;
            }
        },
        setTableTitle: (state, action) => {
            const {
                tableTitle = "",
            } = action.payload || {};

            state.currentTableTitle = tableTitle;
        },
        setTableOrdersListData: (state, action) => {
            const {
                tableOrdersList = [],
                tableOlderOrdersList = [],
                currentOrderId,
            } = action.payload || {};

            state.currentTableOrdersList = tableOrdersList;

            if (currentOrderId !== undefined) {
                state.currentOrderId = currentOrderId;
                state.currentOrderDetails = tableOrdersList?.find(ord => ord.id === currentOrderId) || tableOlderOrdersList?.find(ord => ord.id === currentOrderId) || {};
                state.currentOrderDetailsErrorText = "";
            }

            if (tableOrdersList) {
                state.ordersListItemsAreLoading = false;
            }
        },
        setTableOlderOrdersListData: (state, action) => {
            let {
                tableOlderOrdersList = [],
            } = action.payload || {};

            tableOlderOrdersList = tableOlderOrdersList?.filter(oldOrder => {
                const existingItemInItems = state.currentTableOrdersList?.find(ord => ord.id === oldOrder.id);
                return !existingItemInItems;
            });

            state.currentTableOlderOrdersList = tableOlderOrdersList || [];

            if (tableOlderOrdersList) {
                state.olderOrdersListItemsAreLoading = false;
            }
        },
        changeFiltersAppliedToOrdersList: (state, action) => {
            const {
                showOlderOrders = state.showOlderOrders,
                // showCanceledOrders = state.showCanceledOrders,
                showDeliveredOrders = state.showDeliveredOrders,
                showPaidOrders = state.showPaidOrders,
            } = action.payload;

            let allOrderItems = state.currentTableOrdersList;

            // if (showOlderOrders) {
            //     allOrderItems = allOrderItems?.concat(state.currentTableOlderOrdersList)
            // }

            const ordersListForPreview = allOrderItems?.filter(ord => {
                let filterThisItem;

                // if (!showCanceledOrders) {
                //     filterThisItem = ord.order_status_key === "rejected";
                // }

                if (!filterThisItem && !showDeliveredOrders) {
                    filterThisItem = ord.order_status_key === "delivered";
                }

                if (!filterThisItem && !showPaidOrders) {
                    filterThisItem = ord.payment_status === "paid";
                }

                return !filterThisItem;
            });

            const olderOrdersListForPreview = state.currentTableOlderOrdersList?.filter(ord => {
                let filterThisItem;

                // if (!showCanceledOrders) {
                //     filterThisItem = ord.order_status_key === "rejected";
                // }

                if (!filterThisItem && !showDeliveredOrders) {
                    filterThisItem = ord.order_status_key === "delivered";
                }

                if (!filterThisItem && !showPaidOrders) {
                    filterThisItem = ord.payment_status === "paid";
                }

                return !filterThisItem;
            });

            state.showOlderOrders = showOlderOrders;
            // state.showCanceledOrders = showCanceledOrders;
            state.showDeliveredOrders = showDeliveredOrders;
            state.showPaidOrders = showPaidOrders;

            state.currentTableOrdersListForPreview = ordersListForPreview;
            state.currentTableOlderOrdersListForPreview = olderOrdersListForPreview;
        },
        setSelectedCategoryId: (state, action) => {
            const {
                selectedCategoryId = "",
            } = action.payload || {};

            state.selectedCategoryId = selectedCategoryId;
        },
        selectOrder: (state, action) => {
            const {
                orderId = null,
            } = action.payload || {};

            state.currentOrderId = orderId;
            state.currentOrderDetails = state.currentTableOrdersList?.find(ord => ord.id === orderId) || {};
            state.currentOrderDetailsErrorText = "";
        },
        changeCurrentOrderData: (state, action) => {
            const {
                currentOrderDetails = state.currentOrderDetails,
                errorText = "",
            } = action.payload || {};

            state.currentOrderId = currentOrderDetails?.id;
            state.currentOrderDetails = currentOrderDetails;
            state.currentOrderDetailsErrorText = errorText;
        },
        changeTotalPriceOfOrderAddedItems: (state, action) => {
            const {
                totalPrice,
            } = action.payload || {};

            if (totalPrice === undefined) {
                return;
            }

            state.totalPriceOfOrderAddedItems = totalPrice;
        },
        changeCurrentEditingData: (state, action) => {
            const {
                editingCustomItemType = state.editingCustomItemType,
                initialItemCustomizationForEditing = state.initialItemCustomizationForEditing,
                previewItemDataForEditing = state.previewItemDataForEditing,
            } = action.payload || {};

            state.editingCustomItemType = editingCustomItemType;
            state.initialItemCustomizationForEditing = initialItemCustomizationForEditing;
            state.previewItemDataForEditing = previewItemDataForEditing;

            if (previewItemDataForEditing) {
                state.itemPreviewBoxIsLoading = false;
            }
        },
        openSendToKitchenModal: (state, action) => {
            state.showSendTokitchenModal = true;
        },
        closeSendToKitchenModal: (state, action) => {
            state.showSendTokitchenModal = false;
        },
        openChangeTableModal: (state, action) => {
            state.showChangeTableModal = true;
        },
        closeChangeTableModal: (state, action) => {
            state.showChangeTableModal = false;
        },
        updateItemsForSendToKitchen: (state, action) => {
            const {
                id,
                addToSelected,
                isCustomItem,
            } = action.payload || {};

            if (isCustomItem) {
                if (addToSelected) {
                    state.customItemsForSendToKitchen.push({
                        id: id
                    });
                }
                else {
                    state.customItemsForSendToKitchen = state.customItemsForSendToKitchen.filter(cust_it => cust_it.id !== id);
                }
            } else {
                if (addToSelected) {
                    state.itemsForSendToKitchen.push({
                        id: id
                    });
                }
                else {
                    state.itemsForSendToKitchen = state.itemsForSendToKitchen.filter(it => it.id !== id);
                }
            }

            let allItemsAreAddedForSendToKitchen = true;
            state.currentOrderDetails?.items?.length && state.currentOrderDetails.items.forEach(food => {
                if (allItemsAreAddedForSendToKitchen && !food.is_sent_to_kitchen) {
                    const itemIsAdded = state.itemsForSendToKitchen?.find(it => it.id === food.id) !== undefined;

                    if (!itemIsAdded) {
                        allItemsAreAddedForSendToKitchen = false;
                    }
                }
            });
            state.currentOrderDetails?.customItems?.length && state.currentOrderDetails.customItems.forEach(cust_it => {
                if (allItemsAreAddedForSendToKitchen && !cust_it.is_sent_to_kitchen) {
                    const itemIsAdded = state.customItemsForSendToKitchen?.find(it => it.id === cust_it.id) !== undefined;

                    if (!itemIsAdded) {
                        allItemsAreAddedForSendToKitchen = false;
                    }
                }
            });

            state.allItemsAreSelectedForSendingToKitchen = allItemsAreAddedForSendToKitchen;
        },
        toggleSelectAllItemsForSendingToKitchen: (state, action) => {
            const {
                selectAll = !state.allItemsAreSelectedForSendingToKitchen,
            } = action.payload || {};

            if (selectAll) {
                let items = state.currentOrderDetails?.items?.length && state.currentOrderDetails.items.map(it => {
                    return !it.is_sent_to_kitchen && ({
                        id: it.id
                    })
                }) || [];

                let customItems = state.currentOrderDetails?.customItems?.length && state.currentOrderDetails.customItems.map(cust_it => {
                    return !cust_it.is_sent_to_kitchen && ({
                        id: cust_it.id
                    })
                }) || [];

                items = items.filter(it => it);
                customItems = customItems.filter(cust_it => cust_it);

                state.itemsForSendToKitchen = items;
                state.customItemsForSendToKitchen = customItems;

                state.allItemsAreSelectedForSendingToKitchen = true;
            }
            else {
                state.itemsForSendToKitchen = [];
                state.customItemsForSendToKitchen = [];

                state.allItemsAreSelectedForSendingToKitchen = false;
            }
        },
        toggleTransactionsHistoryModal: (state, action) => {
            const {
                showModal = !state.showTransactionsHistoryModal,
            } = action.payload;

            state.showTransactionsHistoryModal = showModal;
        },
        refreshDashboardData: (state, action) => {
            state.refreshDashboardDataFlag = !state.refreshDashboardDataFlag;
        },
        refreshOrdersListBox: (state, action) => {
            state.refreshOrdersListBoxFlag = !state.refreshOrdersListBoxFlag;
        },
        toggleLoaderForAddedOrdersBox: (state, action) => {
            const {
                isLoading,
            } = action.payload || {};

            if (isLoading !== undefined) {
                state.addedOrdersBoxIsLoading = !!isLoading;
            }
        },
        activateLoaderForTablesList: (state, action) => {
            state.tablesListItemsAreLoading = true;
        },
        activateLoaderForOrdersList: (state, action) => {
            state.ordersListItemsAreLoading = true;
        },
        activateLoaderForOlderOrdersList: (state, action) => {
            state.olderOrdersListItemsAreLoading = true;
        },
        toggleLoaderForCreatingNewOrder: (state, action) => {
            const {
                isLoading = !state.loadingForCreatingNewOrder,
            } = action.payload || {};

            state.loadingForCreatingNewOrder = isLoading;
        },
        activateLoaderForAvailableItemsList: (state, action) => {
            state.availableItemsAreLoading = true;
        },
        activateLoaderForItemPreviewBox: (state, action) => {
            state.itemPreviewBoxIsLoading = true;
        },
    }
});


export const {
    selectStep,
    setTableTitle,
    setTableOrdersListData,
    setTableOlderOrdersListData,
    changeFiltersAppliedToOrdersList,
    setSelectedCategoryId,
    selectOrder,
    changeCurrentOrderData,
    changeTotalPriceOfOrderAddedItems,
    changeCurrentEditingData,
    openSendToKitchenModal,
    closeSendToKitchenModal,
    openChangeTableModal,
    closeChangeTableModal,
    updateItemsForSendToKitchen,
    toggleSelectAllItemsForSendingToKitchen,
    toggleTransactionsHistoryModal,
    refreshDashboardData,
    refreshOrdersListBox,
    toggleLoaderForAddedOrdersBox,
    activateLoaderForTablesList,
    activateLoaderForOrdersList,
    activateLoaderForOlderOrdersList,
    toggleLoaderForCreatingNewOrder,
    activateLoaderForAvailableItemsList,
    activateLoaderForItemPreviewBox,
} = stepManagementSlice.actions;

export default stepManagementSlice.reducer;
