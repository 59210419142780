import './MobileDrawerMenu.scss';

import { useDispatch, useSelector } from "react-redux";
import useRestaurantDetails from "../useRestaurantDetails";
import { useTranslation } from 'react-i18next';
import { changeMobileDrawerMenuShowState, toggleSelectOptionsModal, toggleShowSettingsModal } from '../../store/slices/appGeneralSlice';
import { toggleOpenPaymentsModal } from '../../store/slices/paymentManagementSlice';
import { useEffect, useState } from 'react';
import { LANGUAGES } from '../../constants';
import SelectOptionsModal from './SelectOptionsModal';
import logoIcon from './../../components/images/app-icon-circle.png';

const MobileDrawerMenu = (props) => {
    const {
        handleNavigateToLoginPage,
    } = props;

    const { i18n, t } = useTranslation();

    const initialLanguageCode = localStorage.getItem("langCode") || 'en';
    const selectedLanguageOption = LANGUAGES.find(lang => lang.value === initialLanguageCode);

    const { restaurantDetails, setRestaurantDetails } = useRestaurantDetails();

    const dispatch = useDispatch();

    const showMenu = useSelector(state => state.appGeneral.showMobileDrawerMenu);

    const [drawerState, setDrawerState] = useState("closed");


    useEffect(() => {
        return () => {
            dispatch(changeMobileDrawerMenuShowState({
                show: undefined
            }));
        }
    }, []);

    useEffect(() => {
        if (showMenu !== undefined && showMenu !== null) {
            if (showMenu && (drawerState === "closed" || drawerState === "closing")) {
                setDrawerState("opening");
            }
            else if (drawerState === "opened" || drawerState === "opening") {
                setDrawerState("closing")
            }
        }
    }, [showMenu]);


    const handleCloseMobileDrawerMenu = () => {
        dispatch(changeMobileDrawerMenuShowState({
            show: false
        }));
    };

    const handleShowOpenPeysModal = () => {
        handleCloseMobileDrawerMenu();

        dispatch(toggleOpenPaymentsModal({
            showModal: true,
        }));
    };

    const handleShowLanguageSelectOptions = () => {
        dispatch(toggleSelectOptionsModal({
            showModal: true,
            selectTitle: t("chooseLanguage"),
            selectOptions: LANGUAGES
        }));
    };
    
    const handleSelectLanguageOption = (lang_code) => {
        localStorage.setItem('langCode', lang_code);
        i18n.changeLanguage(lang_code);
        window.location.reload(false);
    };

    const handleDrawerTransitionEnd = (evt) => {
        if (drawerState === "opening") {
            setDrawerState("opened");
        }
        else if (drawerState === "closing") {
            setDrawerState("closed");
        }
    };

    const handleUserLogout = (e) => {
        e.preventDefault();
        handleNavigateToLoginPage();
    };

    const handleOpenSettingsModal = () => {
        dispatch(toggleShowSettingsModal({
            showModal: true
        }));
    };


    const {
        name,
        user_name,
    } = restaurantDetails || {};

    return (
        <>
            <div
                className={`mobile-drawer-menu-overlay is-${drawerState}`}
                style={{
                    display: `${(drawerState === 'closed' && !showMenu) ? 'none' : ''}`
                }}
                onClick={handleCloseMobileDrawerMenu}
            ></div>
            <div
                className={`mobile-drawer-menu-wrapper is-${drawerState}`}
                onTransitionEnd={handleDrawerTransitionEnd}
                style={{
                    display: `${(drawerState === 'closed' && !showMenu) ? 'none' : ''}`
                }}
            >
                <div className="mobile-drawer-menu d-flex flex-column">
                    <div className="menu-header d-flex align-items-center">
                        <span className="user-avatar me-3">
                            <img
                                src={restaurantDetails?.restaurant_logo || logoIcon}
                                width="40"
                                height="40"
                                alt="digiAirMenu logo"
                            />
                            {/* <span className="icon">
                                <svg width="56" height="56" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="M0 0h200v200H0z"/><circle cx="100" cy="100" r="100" fill="#E5E5E5"/><path d="M99.625 95c18.504 0 33.5-14.996 33.5-33.5 0-18.504-14.996-33.5-33.5-33.5-18.504 0-33.5 14.996-33.5 33.5 0 18.504 14.996 33.5 33.5 33.5zm23.45 8.375h-4.371a45.598 45.598 0 01-19.079 4.187c-6.805 0-13.243-1.517-19.08-4.187h-4.37C56.755 103.375 41 119.13 41 138.55v10.888C41 156.373 64 179.5 99.625 178c41.338-1.741 58.625-21.627 58.625-28.562V138.55c0-19.42-15.755-35.175-35.175-35.175z" fill="#fff"/></svg>
                            </span> */}
                        </span>
                        <div className="user-general-info">
                            <div className="fs-5 fw-bold">
                                {name}
                            </div>
                            <div>
                                {user_name}
                            </div>
                        </div>
                    </div>
                    <div className="menu-body d-flex flex-column flex-grow-1">
                        <ul className="menu-items-list flex-grow-1 mb-0">
                            <li>
                                <a
                                    className="menu-item-link"
                                    onClick={handleShowOpenPeysModal}
                                >
                                    {/* {t("openPaymentsReport")} */}
                                    {/* {t("report")} */}
                                    {t("openPayments")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="menu-item-link d-flex justify-content-between align-items-center"
                                    onClick={handleShowLanguageSelectOptions}
                                >
                                    <span>
                                        {t("language")}
                                    </span>
                                    <span className="text-uppercase ms-3">
                                        {selectedLanguageOption?.code || ''}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="menu-item-link"
                                    onClick={handleOpenSettingsModal}
                                >
                                    <span>
                                        {t("settings")}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="menu-footer mb-1">
                        <button
                            className="btn log-out-btn"
                            onClick={handleUserLogout}
                        >
                            <span className="icon me-2">
                                <svg width="22" height="24" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M228.66 164.081c19.426-14.6 47.01-10.688 61.61 8.737 14.6 19.426 10.688 47.009-8.737 61.61C185.55 306.567 128 419.264 128 541.607c0 212.077 171.923 384 384 384s384-171.923 384-384c0-122.476-57.676-235.281-153.834-307.407-19.44-14.58-23.378-42.16-8.797-61.6 14.581-19.44 42.16-23.378 61.6-8.797C913.054 252.375 984 391.136 984 541.608c0 260.678-211.322 472-472 472s-472-211.322-472-472c0-150.31 70.792-288.937 188.66-377.527zM556 384c0 24.3-19.7 44-44 44s-44-19.7-44-44V75c0-24.3 19.7-44 44-44s44 19.7 44 44v309z"/></svg>
                            </span>
                            {t("logout")}
                        </button>
                    </div>
                </div>
            </div>

            <SelectOptionsModal
                handleSelectOption={handleSelectLanguageOption}
                selectedValue={selectedLanguageOption?.value}
            />
        </>
    )
}

export default MobileDrawerMenu;
