
export const BrokenImageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" height="60" width="60" fill="#ebebeb" viewBox="0 0 54 54"><path d="M30.7 36l4.3-4.3 4.7 4.7c1.1 1.1 3.1.6 3.4-1.4 0-.4-.1-.8-.4-1l-6-6c-1.9-1.7-3.4 0-3.4 0L29 32.3l-5.1-5.1h-.1l1.9 7.1 1.6 1.6c1.7 1.9 3.4.1 3.4.1zm16-27H18.9c-.2 0-.3.2-.3.3l.9 3.5c0 .1.1.2.3.2h24.9c.1 0 .3.1.3.3v29.5c0 .1-.1.3-.3.3H27.6c-.1 0-.2.1-.1.2l1 3.7c0 .1.1.1.1.1h18.2c1.3 0 2.3-1 2.3-2.3V11.3C49 10 48 9 46.7 9zM35 19a2 2 0 1 0-4 0 2 2 0 1 0 4 0zM11.4 36.2c.7 1 2.1 1.1 2.9.2l3.7-3.7v-.1l-1.2-4.1s-.1-.1-.1 0l-5.1 5.1c-.6.7-.8 1.8-.2 2.6zm9.1 5c0-.1-.1-.2-.3-.2H11a2 2 0 0 1-2-2V13.4c0-1.3 1.1-2.4 2.4-2.4h0c.2 0 .4-.2.4-.5l-.9-3.3c0-.2-.2-.3-.4-.3H9a4 4 0 0 0-4 4v30a4 4 0 0 0 4 4h12.2c.2 0 .3-.2.3-.4l-1-3.3z" fillRule="evenodd"/></svg>
)

export const CircleAlertIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="currentColor"/><path fillRule="evenodd" clipRule="evenodd" d="M12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7Z" fill="currentColor"/><path fillRule="evenodd" clipRule="evenodd" d="M11 16C11 15.4477 11.4477 15 12 15H12.01C12.5623 15 13.01 15.4477 13.01 16C13.01 16.5523 12.5623 17 12.01 17H12C11.4477 17 11 16.5523 11 16Z" fill="currentColor"/></svg>
)

export const CircleInfoIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="currentColor"/><path fillRule="evenodd" clipRule="evenodd" d="M12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11Z" fill="currentColor"/><path fillRule="evenodd" clipRule="evenodd" d="M11 8C11 7.44772 11.4477 7 12 7H12.01C12.5623 7 13.01 7.44772 13.01 8C13.01 8.55228 12.5623 9 12.01 9H12C11.4477 9 11 8.55228 11 8Z" fill="currentColor"/></svg>
)

export const PlusSquareIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 67.733 67.733" id="add"><path d="M18.094 231.519c-8.73 0-15.841 7.112-15.841 15.842v31.545c0 8.73 7.11 15.842 15.841 15.842h31.545c8.73 0 15.842-7.112 15.842-15.842V247.36c0-8.73-7.112-15.842-15.842-15.842zm0 5.293h31.545c5.89 0 10.55 4.659 10.55 10.549v31.545c0 5.89-4.66 10.548-10.55 10.548H18.094c-5.89 0-10.549-4.658-10.549-10.548V247.36c0-5.89 4.659-10.549 10.549-10.549zm16.395 8.068a2.646 2.646 0 0 0-2.608 2.682v12.752h-12.75a2.646 2.646 0 1 0 0 5.29h12.75v12.75a2.647 2.647 0 1 0 5.293 0v-12.75h12.75a2.646 2.646 0 1 0 0-5.29h-12.75v-12.752a2.646 2.646 0 0 0-2.685-2.682z" fill="currentColor" transform="translate(0 -229.267)"></path></svg>
)

export const CreditCardIconOutlineIcon = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="currentColor" fillRule="evenodd"><path d="M3.375 3.75c-1.036 0-1.875.839-1.875 1.875v9.75c0 1.035.839 1.875 1.875 1.875h14.25c1.035 0 1.875-.84 1.875-1.875v-9.75c0-1.036-.84-1.875-1.875-1.875zM0 5.625C0 3.761 1.511 2.25 3.375 2.25h14.25C19.489 2.25 21 3.761 21 5.625v9.75c0 1.864-1.511 3.375-3.375 3.375H3.375C1.511 18.75 0 17.239 0 15.375z"/><path d="M20.25 8.906H.75V6.094h19.5zM3.094 12.563c0-.777.63-1.406 1.406-1.406h2.25c.777 0 1.406.63 1.406 1.406v.938c0 .777-.63 1.406-1.406 1.406H4.5c-.777 0-1.406-.63-1.406-1.406z"/></svg>
)

export const InfoCircleItalicIcon = () => (
    <svg width="24" height="24" viewBox="0 0 512 512" fill="currentColor" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" xmlns="http://www.w3.org/2000/svg"><path d="M256 0c70.689 0 134.692 28.656 181.02 74.98C483.344 121.308 512 185.311 512 256c0 70.689-28.656 134.692-74.98 181.016C390.692 483.344 326.689 512 256 512c-70.689 0-134.692-28.656-181.016-74.984C28.656 390.692 0 326.689 0 256S28.656 121.308 74.984 74.98C121.308 28.656 185.311 0 256 0zm-8.393 139.828c5.039-12.2 17.404-20.536 30.609-20.536 18.611 0 32.717 15.259 32.717 33.478 0 4.53-.796 8.776-2.407 12.704-6.902 16.91-26.09 25.405-43.082 18.302-16.871-7.122-24.821-27.096-17.837-43.948zm12.103 206.605c-.833 2.984-2.256 7.946-.674 10.725 4.22 7.45 16.459-6.058 19.036-8.97 8.307-9.414 15.461-20.475 21.905-31.229a1.506 1.506 0 012.061-.523l13.44 9.972c.641.473.789 1.363.367 2.03-6.18 10.743-12.426 20.124-18.744 28.129-10.452 13.234-23.595 25.852-39.583 32.065-9.918 3.842-22.817 5.363-34.144 2.829-10.506-2.353-19.66-8.206-23.822-18.946-5.464-14.092-.97-30.105 3.33-43.887l21.689-65.697c2.962-10.647 10.044-29.661-8.25-29.661H197.36c-1.56 0-1.596-1.402-1.297-2.484l4.858-17.685a1.5 1.5 0 011.463-1.103l96.89-3.038c1.409-.05 1.701 1.19 1.374 2.286L259.71 346.433z"/></svg>
)
