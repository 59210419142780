import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    // lng: "en",
    // lng: getCurrentLang(),
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
                login: "Login",
                logout: "Log out",
                signIn: "Sign in",
                signOut: "Sign out",
                profile: "Profile",
                settings: "Settings",
                digiAirMenuWaiterLogin: "DigiAirMenu Waiter Login",
                username: "Username",
                password: "Password",
                pinCode: "PIN code",
                yourAuthenticationFailed: "Your authentication failed",
                addDAMToHomeScreen: "Add DigiAirMenu to your home screen",
                addToHomeScreen: "Add to home screen",
                installDAM: "Install DAM",
                language: "Language",
                chooseLanguage: "Choose language",
                pageNotFound: "Page not found",
                backToDashboard: "Back to dashboard",
                transferToDemo: "Transfer to demo",
                transferToLive: "Transfer to live",
                table: "Table",
                tables: "Tables",
                order: "Order",
                orders: "Orders",
                option: "Option",
                additive: "Additive",
                options: "Options",
                additives: "Additives",
                // itemOptions: "Item options",
                empty: "Empty",
                row: "Row",
                count: "count",
                step: "step",
                title: "Title",
                createdAt: "Created at",
                free: "Free",
                freeItems: "free items",
                items: "items",
                item: "item",
                minimum: "minimum",
                maximum: "Maximum",
                min: "min",
                max: "max",
                all: "All",
                email: "Email",
                print: "Print",
                printUsingExternalDevice: "Print using external device",
                orderDetails: "Order details",
                noOrders: "No orders",
                noOrdersAdded: "No orders added",
                noAddedItems: "No items have been added",
                createOrSelectOrderAfterSelectingTable: "First select a table and then create/select an order",
                createOrSelectOrderFirst: "Create/select an order at the first",
                customFood: "Custom food",
                customFoods: "Custom foods",
                normalFoodOrDrink: "normal food/drink",
                customMenuItems: "Custom menu items",
                delivered: "delivered",
                sentToKitchen: "Sent to kitchen",
                sendToKitchen: "Send to kitchen",
                orderDelivered: "Order delivered",
                orderDeliveredConfirmation: "Are you sure this order has been delivered?",
                selectItemsForSendingToKitchen: "Select items for sending to kitchen",
                type: "type",
                status: "status",
                ItemDetailsAndStatus: "Item details / status",
                description: "description",
                typeAnythingYouNeed: "Type anything you need",
                paid: "paid",
                isPaid: "Is paid",
                total: "Total",
                editOrderItem: "Edit order item",
                chooseTable: "Choose Table",
                pickup: "Pickup",
                suspended: "Suspended",
                processing: "Processing",
                rejected: "Rejected",
                delivered: "Delivered",
                ordersList: "Orders List",
                showOlderOrders: "Show older orders",
                showHistory: "Show history",
                showCanceledOrders: "Show canceled orders",
                showDeliveredOrders: "Show delivered orders",
                showPaidOrders: "Show paid orders",
                listOfOrders: "List of orders",
                addNewOrder: "Add new order",
                ordering: "Ordering",
                availableFoods: "Available Foods",
                searchByTableName: "Search by table name",
                Layout: "Layout",
                gridView: "Grid view",
                listView: "List view",
                Filters: "Filters",
                availableItems: "Available Items",
                searchByFoodName: "Search by food name",
                allCategories: "All Categories",
                noCategories: "No Categories",
                addFood: "Add Food",
                areYouSureYouWantToConvert: "Are you sure you want to convert?",
                areYouWantDeleteThisItem: "Are you sure you want to delete this item?",
                areYouWantCancelOrder: "Are you sure you want to cancel this order?",
                getEmailLabel: "Please enter your email",
                enterValidEmailErr: "Please enter a valid email.",
                sendEmail: "Send email",
                no: "No",
                yes: "Yes",
                cancel: "Cancel",
                close: "Close",
                back: "Back",
                next: "Next",
                previous: "Previous",
                preview: "Preview",
                changeTable: "Change table",
                saveChanges: "Save changes",
                confirm: "Confirm",
                unselectAll: "Unselect all",
                selectAll: "Select all",
                selected: "selected",
                reset: "Reset",
                clear: "Clear",
                mobileKeyboard: "Mobile keyboard",
                addToOrders: "add to orders",
                yesDeleteIt: "Yes, Delete it",
                cancelThisOrder: "Cancel this order",
                cancelOrder: "Cancel order",
                // in the text below, the number '1' represents the section number (starting from 0) in the main jsx tag
                orderModeChangedToDemoInJSX: "The desired order mode was changed to demo mode with the title '<1>Order {{targetOrder_id}}</1>'.",
                orderModeChangedToLiveInJSX: "The desired order mode was changed to live mode with the title '<1>Order {{targetOrder_id}}</1>'.",
                checkoutHasPaidByPosInJSX: "The <1>Checkout</1> with id <3>{{checkout_id}}</3> from <5>order {{orderId_of_checkout}}</5> has successfully paid through POS.",
                checkoutHasPaidByPosInJSXSimpleText: "The <1>Checkout</1> with id <3>{{checkout_id}}</3> has successfully paid through POS.",
                demo: "Demo",
                normal: "Normal",
                showOrderInTargetMode: "Show order in {{targetMode}} Mode",
                showOrder: "Show order",
                yesDeleteIt: "Yes, Delete it",
                pay: "Pay",
                payment: "Payment",
                payments: "Payments",
                payReport: "Pay report",
                paymentOf: "Payment of",
                checkoutOperation: "Checkout operation",
                itemsToPay: "Items to pay",
                price: "Price",
                basicPrice: "Basic price",
                totalPrice: "Total price",
                baseAmount: "Base amount",
                paidPrice: "Paid price",
                paidBy: "Paid by",
                discount: "Discount",
                discountAmount: "Discount amount",
                tipPrice: "Tip price",
                totalWithTip: "Total with tip",
                // discountedPayments: "Discounted payments",
                // paymentsWithoutDiscount: "Payments without Discount",
                paymentsIncludingDiscounts: "Payments including discounts",
                paymentsExcludingDiscounts: "Payments excluding discounts",
                paymentsTakingIntoAccountTheirDiscount: "Payments taking into account their discount",
                paymentsRegardlessOfTheirDiscount: "Payments regardless of their discount",
                openPaymentsStatistics: "Statistics of open payments",
                discountCannotBeLessThanZero: 'The amount of discount cannot be less than "0"',
                discountCannotBeNegative: "The discount amount cannot be negative",
                discountCannotBeMoreThanHundredPercent: 'The amount of discount cannot be more than "100%"',
                discountCannotBeMoreThan: 'The discount amount cannot be more than {{price}}',
                discountCannotBeMoreThanBaseAmount: 'The discount amount cannot be more than the "Base Amount"',
                theValueMustBeMoreThan: "The value must be more than {{price}}",
                theValueMustBeMoreThanBaseAmount: "The value must be more than Base Amount",
                tip: "Tip",
                tipAmount: "Tip amount",
                payByCreditCard: "Pay by Credit Card",
                payInCash: "Pay in Cash",
                checkout: "Checkout",
                transaction: "Transaction",
                noTransactionHasBeenMade: "No transaction has been made",
                backToTransactionsList: "Back to transactions list",
                transactionsHistory: "Transactions history",
                transactionDetails: "Transaction details",
                transactionId: "Transaction Id",
                transactionHasBeenSentToYourEmail: "The transaction has been sent to your email",
                paymentType: "Payment type",
                paymentStatus: "Payment status",
                ordersPrice: "Orders price",
                totalAmount: "Total amount",
                updatedAt: "Updated at",
                fromTime: "From time",
                toTime: "To time",
                backToOrders: "Back to orders",
                closeAndBackToOrders: "Close and back to orders",
                tablesList: "Tables list",
                backToTablesList: "Back to tables list",
                closeAndBackToTablesList: "Close and back to tables list",
                showDetails: "Show details",
                receiptDetailsPage: "Receipt details page",
                vat: "VAT",
                net: "Net",
                tax: "Tax",
                gross: "Gross",
                sendingEmailFailed: "Sending email failed!",
                paymentFailed: "Payment failed",
                openPayments: "Open payments",
                Open_Payments: "Open-Payments",
                openPaymentsReport: "Open payments report",
                report: "Report",
                openPaymentStatistics: "Open payment statistics",
                allOrderItemsHaveBeenPaid: "All items on this order have been paid",
                noItemsHaveBeenAddedInThisOrder: "No items have been added in this order",
                thisItemHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "This item has been paid through POS and is awaiting payment confirmation",
                yourOrderHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "Your Order has been paid through POS and is awaiting payment confirmation",
                generalSettings: "General settings",
                useAppConnectedPrinter: "Use app connected printer",
                youCanTurnOffThisOptionToSelectPrinterFromTheAvailableConnectedDevices: "you can turn off this option to select a printer from the available connected devices",
                selectAllPayablesByDefault: "Select all payables by default",
                selectAllPayablesByDefaultOnThePaymentPage: "Select all payables by default on the payment page",
            }
        },
        de: {
            translation: {
                login: "Anmeldung",
                logout: "Ausloggen",
                signIn: "anmelden",
                signOut: "Abmelden",
                profile: "Profil",
                settings: "Einstellungen",
                digiAirMenuWaiterLogin: "DigiAirMenu-Kassen-Login",
                username: "Nutzername",
                password: "Passwort",
                pinCode: "Geheimzahl",
                yourAuthenticationFailed: "Ihre Authentifizierung ist fehlgeschlagen",
                addDAMToHomeScreen: "Fügen Sie DigiAirMenu zu Ihrem Startbildschirm hinzu",
                addToHomeScreen: "Zum Startbildschirm hinzufügen",
                installDAM: "Installieren Sie DAM",
                language: "Sprache",
                chooseLanguage: "Sprache wählen",
                pageNotFound: "Seite nicht gefunden",
                backToDashboard: "Zurück zum Dashboard",
                transferToDemo: "Zur Demo übertragen",
                transferToLive: "Ins Leben übertragen",
                table: "Tisch",
                tables: "Tische",
                order: "Bestellung",
                orders: "Bestellungen",
                option: "Möglichkeit",
                additive: "Zusatzstoff",
                options: "Optionen",
                additives: "Additive",
                // itemOptions: "Artikeloptionen",
                empty: "Leer",
                row: "Reihe",
                count: "die Zählung",
                step: "Schritt",
                title: "Titel",
                createdAt: "Hergestellt in",
                free: "Frei",
                freeItems: "kostenlose Artikel",
                items: "Artikel",
                item: "Artikel",
                minimum: "Mindest",
                maximum: "Maximal",
                min: "min",
                max: "max",
                all: "Alle",
                email: "Email",
                print: "Drucken",
                printUsingExternalDevice: "Drucken Sie mit einem externen Gerät",
                orderDetails: "Bestelldetails",
                noOrders: "Keine Befehle",
                noOrdersAdded: "Keine Bestellungen hinzugefügt",
                noAddedItems: "Es wurden keine Artikel hinzugefügt",
                createOrSelectOrderAfterSelectingTable: "Wählen Sie zunächst einen Tisch aus und erstellen/wählen Sie dann eine Bestellung",
                createOrSelectOrderFirst: "Erstellen/wählen Sie zunächst eine Bestellung",
                customFood: "Maßgeschneidertes Essen",
                customFoods: "Maßgeschneiderte Lebensmittel",
                normalFoodOrDrink: "normales Essen/Getränk",
                customMenuItems: "Benutzerdefinierte Menüelemente",
                delivered: "geliefert",
                sentToKitchen: "In die Küche geschickt",
                sendToKitchen: "In die Küche schicken",
                orderDelivered: "Bestellung geliefert",
                orderDeliveredConfirmation: "Sind Sie sicher, dass diese Bestellung geliefert wurde?",
                selectItemsForSendingToKitchen: "Wählen Sie Artikel aus, die an die Küche gesendet werden sollen",
                type: "Typ",
                status: "Status",
                ItemDetailsAndStatus: "Artikeldetails / Status",
                description: "Beschreibung",
                typeAnythingYouNeed: "Geben Sie alles ein, was Sie brauchen",
                paid: "bezahlt",
                isPaid: "Ist bezahlt",
                total: "Gesamt",
                editOrderItem: "Bestellposition bearbeiten",
                chooseTable: "Wählen Sie Tabelle",
                pickup: "Pickup",
                suspended: "Ausgesetzt",
                processing: "wird bearbeitet",
                rejected: "Abgelehnt",
                delivered: "Geliefert",
                ordersList: "Auftragsliste",
                showOlderOrders: "Ältere Bestellungen anzeigen",
                showHistory: "Zeige die Geschichte",
                showCanceledOrders: "Stornierte Bestellungen anzeigen",
                showDeliveredOrders: "Ausgelieferte Bestellungen anzeigen",
                showPaidOrders: "Bezahlte Bestellungen anzeigen",
                listOfOrders: "Liste der Bestellungen",
                addNewOrder: "Neue Bestellung hinzufügen",
                ordering: "Bestellung",
                availableFoods: "Verfügbare Lebensmittel",
                searchByTableName: "Suche nach Tabellennamen",
                Layout: "Layout",
                gridView: "Rasteransicht",
                listView: "Listenansicht",
                Filters: "Filter",
                availableItems: "Verfügbare Artikel",
                searchByFoodName: "Suche nach Lebensmittelnamen",
                allCategories: "Alle Kategorien",
                noCategories: "Keine Kategorien",
                addFood: "Add Food",
                areYouSureYouWantToConvert: "Sind Sie sicher, dass Sie konvertieren möchten?",
                areYouWantDeleteThisItem: "Sind Sie sicher, dass Sie dieses Element löschen möchten ?",
                areYouWantCancelOrder: "Sind Sie sicher, dass Sie diese Bestellung stornieren möchten ?",
                getEmailLabel: "Bitte geben Sie ihre E-Mail-Adresse ein",
                enterValidEmailErr: "Bitte geben Sie eine gültige Email-Adresse ein.",
                sendEmail: "E-Mail senden",
                no: "Nein",
                yes: "Ja",
                cancel: "Stornieren",
                close: "Schließen",
                back: "zurück",
                next: "Nächstes",
                previous: "Vorherige",
                preview: "Vorschau",
                changeTable: "Tisch wechselns",
                saveChanges: "Änderungen speichern",
                confirm: "Bestätigen",
                unselectAll: "Alles wiederufen",
                selectAll: "Wählen Sie Alle",
                selected: "ausgewählt",
                reset: "Zurücksetzen",
                clear: "Klar",
                mobileKeyboard: "Mobile Tastatur",
                addToOrders: "zu Bestellungen hinzufügen",
                yesDeleteIt: "Ja, löschen Sie es",
                cancelThisOrder: "Stornieren Sie diese Bestellung",
                cancelOrder: "Bestellung stornieren",
                // in the text below, the number '1' represents the section number (starting from 0) in the main jsx tag
                orderModeChangedToDemoInJSX: "Der gewünschte Bestellmodus wurde in den Demomodus mit dem Titel '<1>Bestellung {{targetOrder_id}}</1>' geändert.",
                orderModeChangedToLiveInJSX: "Der gewünschte Bestellmodus wurde in den Live-Modus mit dem Titel '<1>Bestellung {{targetOrder_id}}</1>' geändert.",
                checkoutHasPaidByPosInJSX: "Der <1>Checkout</1> mit der ID <3>{{checkout_id}}</3> aus <5>Bestellung {{orderId_of_checkout}}</5> wurde erfolgreich über POS bezahlt.",
                checkoutHasPaidByPosInJSXSimpleText: "Der <1>Checkout</1> mit der ID <3>{{checkout_id}}</3> wurde erfolgreich über POS bezahlt.",
                demo: "Demo",
                normal: "Normal",
                showOrderInTargetMode: "Reihenfolge im {{targetMode}}-Modus anzeigen",
                showOrder: "Bestellung anzeigen",
                yesDeleteIt: "Ja, löschen Sie es",
                pay: "Zahlen",
                payment: "Zahlung",
                payments: "Zahlungen",
                payReport: "Gehaltsbericht",
                paymentOf: "Bezahlung der",
                checkoutOperation: "Kassenbetrieb",
                itemsToPay: "Zu bezahlende Artikel",
                price: "Preis",
                basicPrice: "Grundpreis",
                totalPrice: "Gesamtpreis",
                baseAmount: "Grundbetrag",
                paidPrice: "Bezahlter Preis",
                paidBy: "Bezahlt von",
                discount: "Rabatt",
                discountAmount: "Rabattbetrag",
                tipPrice: "Trinkgeldpreis",
                totalWithTip: "Total mit Trinkgeld",
                // discountedPayments: "Ermäßigte Zahlungen",
                // paymentsWithoutDiscount: "Zahlungen ohne Skonto",
                paymentsIncludingDiscounts: "Payments including discounts",
                paymentsExcludingDiscounts: "Zahlungen ohne Skonti",
                paymentsTakingIntoAccountTheirDiscount: "Zahlungen unter Berücksichtigung ihres Skontos",
                paymentsRegardlessOfTheirDiscount: "Zahlungen unabhängig von ihrem Rabatt",
                openPaymentsStatistics: "Statistik offener Zahlungen",
                discountCannotBeLessThanZero: 'Der Rabattbetrag darf nicht kleiner als "0" sein.',
                discountCannotBeNegative: "Der Rabattbetrag darf nicht negativ sein",
                discountCannotBeMoreThanHundredPercent: 'Der Rabattbetrag darf nicht mehr als "100%" betragen.',
                discountCannotBeMoreThan: 'Der Rabattbetrag darf nicht mehr als {{price}} betragen',
                discountCannotBeMoreThanBaseAmount: 'Der Rabattbetrag darf nicht höher sein als der "Grundbetrag".',
                theValueMustBeMoreThan: "Der Wert muss mehr als {{price}} betragen",
                theValueMustBeMoreThanBaseAmount: "Der Wert muss größer als der Basisbetrag sein",
                tip: "Trinkgeldbetrag",
                tipAmount: "Trinkgeldbetrag",
                payByCreditCard: "Mit Kreditkarte bezahlen",
                payInCash: "Bar bezahlen",
                checkout: "Kasse",
                transaction: "Transaktion",
                noTransactionHasBeenMade: "Es wurde keine Transaktion durchgeführt",
                backToTransactionsList: "zurück zur Transaktionsliste",
                transactionsHistory: "Transaktionsverlauf",
                transactionDetails: "Transaktionsdetails",
                transactionId: "Transaktions-ID",
                transactionHasBeenSentToYourEmail: "Die Transaktion wurde an Ihre E-Mail-Adresse gesendet",
                paymentType: "Zahlungsart",
                paymentStatus: "Zahlungsstatus",
                ordersPrice: "Bestellpreis",
                totalAmount: "Gesamtmenge",
                updatedAt: "aktualisiert am",
                fromTime: "Von Zeit",
                toTime: "Zur Zeit",
                backToOrders: "Zurück zu Bestellungen",
                closeAndBackToOrders: "Schließen und zurück zu den Bestellungen",
                tablesList: "Tabellenliste",
                backToTablesList: "Zurück zur Tabellenliste",
                closeAndBackToTablesList: "Schließen und zurück zur Tabellenliste",
                showDetails: "zeige Details",
                receiptDetailsPage: "Seite mit Empfangsdetails",
                vat: "MwSt",
                net: "Netto",
                tax: "Steuer",
                gross: "Brutto",
                sendingEmailFailed: "E-Mail-Versand fehlgeschlagen!",
                paymentFailed: "Bezahlung fehlgeschlagen",
                openPayments: "Offene Zahlungen",
                Open_Payments: "Offene Zahlungen",
                openPaymentsReport: "Offener Zahlungsbericht",
                report: "Bericht",
                openPaymentStatistics: "Zahlungsstatistik öffnen",
                allOrderItemsHaveBeenPaid: "Alle Artikel dieser Bestellung wurden bezahlt",
                noItemsHaveBeenAddedInThisOrder: "In dieser Bestellung wurden keine Artikel hinzugefügt",
                thisItemHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "Dieser Artikel wurde über POS bezahlt und wartet auf die Zahlungsbestätigung",
                yourOrderHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "Ihre Bestellung wurde über POS bezahlt und wartet auf die Zahlungsbestätigung",
                generalSettings: "Allgemeine Einstellungen",
                useAppConnectedPrinter: "Verwenden Sie einen mit der App verbundenen Drucker",
                youCanTurnOffThisOptionToSelectPrinterFromTheAvailableConnectedDevices: "Sie können diese Option deaktivieren, um einen Drucker aus den verfügbaren angeschlossenen Geräten auszuwählen",
                selectAllPayablesByDefault: "Wählen Sie standardmäßig alle Verbindlichkeiten aus",
                selectAllPayablesByDefaultOnThePaymentPage: "Wählen Sie auf der Zahlungsseite standardmäßig alle Verbindlichkeiten aus",
            }
        },
    }
  });

export default i18n;