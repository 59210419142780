import { createSlice } from "@reduxjs/toolkit";


const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        showTransactionModal: false,
        currentTransactionDetails: {},
        showEmailPromptModal: false,
        targetCheckoutDetails: {},
    },
    reducers: {
        toogleTransactionDetailsModalOfPayment: (state, action) => {
            const {
                showModal,
            } = action.payload || {};

            if (showModal === undefined) {
                return;
            }
            state.showTransactionModal = showModal;
            state.currentTransactionDetails = {};
        },
        setTransactionDetails: (state, action) => {
            const {
                details = {},
            } = action.payload || {};

            state.currentTransactionDetails = details;
        },
        changeCheckoutDetailsInTransactionData: (state, action) => {
            const {
                new_checkoutDetails = {},
            } = action.payload || {};

            const target_checkoutId = new_checkoutDetails.order_pay?.checkout_id;

            const transactionDetails_clone = {...state.currentTransactionDetails};
            const checkouts_clone = [...transactionDetails_clone?.checkouts || []];

            const new_checkouts_listData = Array.isArray(checkouts_clone) && checkouts_clone.map((checkoutData) => {
                const is_target_checkout = checkoutData.checkout_id === target_checkoutId;

                const new_checkoutData = {...checkoutData} || {};
                if (is_target_checkout) {
                    new_checkoutData.payment_status = new_checkoutDetails.order_pay?.payment_status;

                    new_checkoutData.receipt_temp_link = new_checkoutDetails.order_pay?.receipt_temp_link;
                    new_checkoutData.updated_at = new_checkoutDetails.order_pay?.updated_at;

                    const new_taxesData = new_checkoutDetails.taxes || new_checkoutDetails.order_pay?.taxes;
                    if (new_taxesData?.length) {
                        new_checkoutData.taxes = new_taxesData;
                    }
                }
                return new_checkoutData;
            });

            state.currentTransactionDetails = {
                ...state.currentTransactionDetails,
                checkouts: new_checkouts_listData
            };
        },
        toogleGetEmailPromptModal: (state, action) => {
            const {
                showModal,
            } = action.payload || {};

            if (showModal === undefined) {
                return;
            }
            state.showEmailPromptModal = showModal;
        },
        setTargetCheckoutDetails: (state, action) => {
            const {
                checkoutDetails,
            } = action.payload;

            state.targetCheckoutDetails = checkoutDetails || {};
        },
    }
});


export const {
    toogleTransactionDetailsModalOfPayment,
    toogleGetEmailPromptModal,
    setTransactionDetails,
    changeCheckoutDetailsInTransactionData,
    setTargetCheckoutDetails,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
