import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeOpenPaymentsData, toggleOpenPaymentsModal } from "../../store/slices/paymentManagementSlice";

import './Payment.scss';
import { useTranslation } from "react-i18next";
import CustomModal from "../customModal";
import useRestaurantDetails from "../useRestaurantDetails";

import ReactEcharts from "echarts-for-react"; 
import React, { useEffect, useRef } from "react";
import Select from 'react-select';
import { fetchOpenPayDetails } from "../../helpers/apiUtilities";
import PaymentStatisticsPlaceholder from "../Layouts/PaymentStatisticsPlaceholder";


const OpenPaymentsModalContent = (props) => {
    const {
        openPaymentsData,
    } = props;

    const {
        details: openPayments_detailsList,
    } = openPaymentsData || {};

    const { i18n, t } = useTranslation();

    const {restaurantDetails} = useRestaurantDetails();

    const {
        currency
    } = restaurantDetails || {};

    const chartWrapperRefs = useRef([]);


    const chartGeneralOption = {
        title: {
            // text: t("Open_Payments"),
            left: 'center',
            subtextStyle: {
                color: '#999',
                fontWeight: 'normal',
                fontSize: 14
            }
        },
        tooltip: {
            trigger: 'item',
            // formatter: '{a} <br/>{b} : {c} ({d}%)',
            // formatter: '{a} <br/><strong style="color: red; font-size: 20px; vertical-align: middle; margin-top: -3px;">&bull;</strong> {b} : {c} ({d}%)',
            formatter: function(params) {
                var tooltipContent =
                    `<span>${params.seriesName}</span>
                    <br/>
                    <div style="display: flex; justify-content: space-between;">
                        <span style="margin-right: 20px;">${params.marker} ${params.name} (${currency})</span>
                        <span>${params.value} (${params.percent}%)</span>
                    </div>`;    
                return tooltipContent;
            },
        },
        legend: {
            bottom: 0,
            left: 'center',
            // orient: "vertical",
            // top: 'center',
            // left: 0,
            // data: []
        },
        // series: []
    };
    const chartGeneralOptionSerieItem = {
        type: 'pie',
        // name: '',
        // height: '72%',
        height: '82%',
        // top: 50,
        top: 'center',
        // center: ['50%', '50%'],
        center: ['65%', '50%'],
        selectedMode: 'single',
        // radius: ['38%', '70%'],
        itemStyle: {
            borderRadius: 6,
            borderColor: '#fff',
            borderWidth: 5,
        },
        emphasis: {
            label: {
                show: true,
                fontSize: 15,
                fontWeight: 'bold'
            }
        },
        // data: []
    };


    const getCorrectNumber = (num) => {
        const correctNumber = +num.toString().replaceAll(",", "");
        return correctNumber;
    }


    return (
        <div className="mt-4">
            <div className="overflow-auto custom-scrollbar mx-3">
                <table className="table open-payments-table table-bordered table-rounded text-center mt-4 mx-auto">
                    <thead className="table-light text-capitalize">
                        <tr>
                            <th>{t("paymentType")}</th>
                            <th>{t("ordersPrice")} {currency}</th>
                            <th>{t("tip")} {currency}</th>
                            <th>{t("totalAmount")} {currency}</th>
                            <th>{t("discount")} {currency}</th>
                            <th>{t("paidPrice")} {currency}</th>
                            <th>{t("fromTime")}</th>
                            <th>{t("toTime")}</th>
                        </tr>
                    </thead>
                    <tbody className="fs-4 fw-lighter">
                        {openPayments_detailsList?.length && openPayments_detailsList.map((pay_details, index) => {
                            const {
                                // source_type,
                                amount,
                                discount_price,
                                paid_price,
                                orders_price,
                                tip_price,
                                from_time,
                                to_time,
                                pay_type
                            } = pay_details || [];

                            // const fromTime = new Date(from_time).toLocaleString();
                            // const toTime = new Date(to_time).toLocaleString();

                            const fromTimeOrg = new Date(from_time);
                            const toTimeOrg = new Date(from_time);

                            const fromTime = `${fromTimeOrg.getDate()}/${fromTimeOrg.getMonth() + 1}/${fromTimeOrg.getFullYear()}, ${fromTimeOrg.getHours()}:${fromTimeOrg.getMinutes()}:${fromTimeOrg.getSeconds()}`;

                            const toTime = `${toTimeOrg.getDate()}/${toTimeOrg.getMonth() + 1}/${toTimeOrg.getFullYear()}, ${toTimeOrg.getHours()}:${toTimeOrg.getMinutes()}:${toTimeOrg.getSeconds()}`;

                            return (
                                <tr
                                    // key={index}
                                    key={pay_type}
                                >
                                    <td>{pay_type}</td>
                                    <td>{orders_price}</td>
                                    <td>{tip_price}</td>
                                    <td>{amount}</td>
                                    <td>{discount_price}</td>
                                    <td>{paid_price}</td>
                                    <td>{fromTime}</td>
                                    <td>{toTime}</td>
                                </tr>
                            )
                        }) || (
                            <tr></tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="charts-wrapper d-flex flex-wrap justify-content-center">
                {openPayments_detailsList?.length && openPayments_detailsList.map((pay_details, index) => {
                    const {
                        // source_type,
                        // amount,
                        discount_price,
                        paid_price,
                        orders_price,
                        tip_price,
                        // from_time,
                        // to_time,
                        pay_type,
                    } = pay_details || [];

                    const chrtOpt = {
                        ...chartGeneralOption,
                        title: {
                            ...chartGeneralOption?.title,
                            text: `${pay_type} ${t("payments")}`,
                            // subtext: `${t("paidBy")}: ${pay_type}`
                        },
                        legend: {
                            ...chartGeneralOption?.legend,
                            data: [t("paidPrice"), t("ordersPrice"), t("discount"), t("tip")]
                        },
                        series: [
                            {
                                ...chartGeneralOptionSerieItem,
                                // name: t("paymentsWithoutDiscount"),
                                // name: t("paymentsExcludingDiscounts"),
                                name: t("paymentsRegardlessOfTheirDiscount"),
                                radius: ['35%', '65%'],
                                height: '50%',
                                center: ['25%', '50%'],
                                // label: {
                                //     position: 'inner',
                                //     fontSize: 14
                                // },
                                // labelLine: {
                                //     show: false
                                // },
                                // itemStyle: {
                                //     ...chartGeneralOptionSerieItem.itemStyle,
                                //     borderRadius: 3,
                                //     borderWidth: 2
                                // },
                                label: {
                                    // alignTo: 'edge',
                                    formatter: `{name|{b}}\n{value|{c} ${currency}  ({d}%)}`,
                                    minMargin: 5,
                                    edgeDistance: 10,
                                    lineHeight: 15,
                                    rich: {
                                        value: {
                                            fontSize: 11,
                                            fontWeight: 600,
                                            color: '#929292'
                                        }
                                    }
                                },
                                labelLine: {
                                    length: 15,
                                    length2: 5,
                                    maxSurfaceAngle: 80
                                },
                                // avoidLabelOverlap: false,
                                labelLayout: function (params) {
                                    const chartWrapperElem = chartWrapperRefs?.current[index];
                                    if (chartWrapperElem) {
                                        const chartWidth = chartWrapperElem.getBoundingClientRect().width;
                                        const isLeft = params.labelRect.x < (chartWidth * 0.25);

                                        const points = params.labelLinePoints;
                                        // Update the end point.
                                        points[2][0] = isLeft
                                            ? params.labelRect.x
                                            : params.labelRect.x + params.labelRect.width;

                                        return {
                                            labelLinePoints: points
                                        };
                                    }
                                },
                                data: [
                                    {
                                        name: t("ordersPrice"),
                                        value: getCorrectNumber(orders_price)
                                    },
                                    {
                                        name: t("tip"),
                                        value: getCorrectNumber(tip_price)
                                    }
                                ]
                            },
                            {
                                ...chartGeneralOptionSerieItem,
                                // name: t("discountedPayments"),
                                // name: t("paymentsIncludingDiscounts"),
                                name: t("paymentsTakingIntoAccountTheirDiscount"),
                                radius: ['35%', '65%'],
                                height: '70%',
                                center: ['72%', '50%'],
                                label: {
                                    // alignTo: 'edge',
                                    formatter: `{name|{b}}\n{value|{c} ${currency}  ({d}%)}`,
                                    minMargin: 5,
                                    edgeDistance: 10,
                                    lineHeight: 15,
                                    rich: {
                                        value: {
                                            fontSize: 11,
                                            fontWeight: 600,
                                            color: '#929292'
                                        }
                                    }
                                },
                                labelLine: {
                                    length: 15,
                                    length2: 5,
                                    maxSurfaceAngle: 80
                                },
                                // avoidLabelOverlap: false,
                                labelLayout: function (params) {
                                    const chartWrapperElem = chartWrapperRefs?.current[index];
                                    if (chartWrapperElem) {
                                        const chartWidth = chartWrapperElem.getBoundingClientRect().width;
                                        const isLeft = params.labelRect.x < (chartWidth * 0.7);

                                        const points = params.labelLinePoints;
                                        // Update the end point.
                                        points[2][0] = isLeft
                                            ? params.labelRect.x
                                            : params.labelRect.x + params.labelRect.width;

                                        return {
                                            labelLinePoints: points
                                        };
                                    }
                                },
                                data: [
                                    {
                                        name: t("paidPrice"),
                                        value: getCorrectNumber(paid_price)
                                    },
                                    {
                                        name: t("discount"),
                                        value: getCorrectNumber(discount_price)
                                    },
                                    {
                                        name: t("tip"),
                                        value: getCorrectNumber(tip_price)
                                    }
                                ]
                            }
                        ]
                    };

                    return (
                        <div
                            key={pay_type}
                            ref={elem => chartWrapperRefs.current[index] = elem}
                            className="chart-item-wrapper px-3 mt-4"
                        >
                            <ReactEcharts option={chrtOpt} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

const PaymentStatisticsModal = (props) => {
    // const {
    // } = props;

    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();

    const showModal = useSelector(state => state.paymentManagement.showOpenPaymentsModal);

    const openPaymentsData = useSelector(state => state.paymentManagement.openPaymentsData);


    useEffect(() => {
        handleShowOpenPaymentsData();
    }, []);


    const handleCloseModal = () => {
        dispatch(toggleOpenPaymentsModal({
            showModal: false,
        }));
    };


    const handleShowOpenPaymentsData = async (slctdOpt) => {
        dispatch(changeOpenPaymentsData({
            data: null
        }));

        const response = await fetchOpenPayDetails();
        if (response?.status === 200) {
            const result = response.data;
            const open_payments = result?.data;

            dispatch(changeOpenPaymentsData({
                data: {
                    details: open_payments
                }
            }));
        }
        // else {
        //     // 
        // }
    };


    return (
        <CustomModal
            size="lg"
            show={showModal}
            onHide={handleCloseModal}
            className="open-payments-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-capitalize">
                    {t("openPaymentsStatistics")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!openPaymentsData ? (
                    <PaymentStatisticsPlaceholder />
                ) : (
                    <OpenPaymentsModalContent
                        openPaymentsData={openPaymentsData}
                    />
                )}
            </Modal.Body>
            <Modal.Footer className="text-end">
                <button
                    className="btn btn-lg btn-dark ms-3 me-3 fw-medium text-uppercase"
                    onClick={handleCloseModal}
                >
                    {t("close")}
                </button>
            </Modal.Footer>
        </CustomModal>
    )
}

export default PaymentStatisticsModal;

