// import axios from "axios";
import cashBoxApi from "../dataProvider/cashBoxApi";
import { History } from "./NavigateSetter";


const redirectToLoginPage = () => {
    // window.location.href = "/login";
    History.navigate({
        pathname: '/login',
        search: '?auth_err=true',
    });
}

const callCashBoxApi = async (config) => {
    let response;

    config = config || {};
    config.headers = config.headers || {};
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

    try {
        response = await cashBoxApi(config);
    }
    catch (err) {
        // if (axios.isCancel(err)) {
        //     console.warn(`The request was canceled! \n path: ${config.url} \n method: ${config.method}`)
        // }
        // else if (err?.response?.status === 401) {
        if (err?.response?.status === 401) {
            console.error(err?.message);
            redirectToLoginPage();
        }
        else {
            console.error(err?.message);
        }

        return err;
    }
    finally {
        // 
    }

    return response;
}

// const getPromiseWithCancelationMethod = (config) => {
//     const cancelToken = axios.CancelToken.source();
//     config.cancelToken = cancelToken.token;

//     const promise = callCashBoxApi(config);

//     return {
//         requestCancelation: cancelToken.cancel,
//         promise: () => promise
//     }
// };


export const loginUser = (credentials) => {
    const config = {
        method: 'post',
        url: `/auth/login`,
        data: credentials
    };
    return callCashBoxApi(config);
};

export const fetchTablesList = () => {
    const config = {
        method: 'get',
        url: `/table/list`,
    };
    return callCashBoxApi(config);
};

export const fetchOrdersList = (tableId, get_history) => {
    const config = {
        method: 'get',
        url: `/order/list?table_id=${tableId}${get_history ? '&history_filter=true' : ''}`,
    };
    return callCashBoxApi(config);

    // return getPromiseWithCancelationMethod(config);
};

export const fetchOrderDetails = (orderId) => {
    const config = {
        method: 'get',
        url: `/order/details?order_id=${orderId}`,
    };
    return callCashBoxApi(config);
};

export const fetchCategoriesList = () => {
    var config = {
        method: 'get',
        url: `/category/list`,
    };
    return callCashBoxApi(config);
};

export const fetchAllFoodsList = (cat_id) => {
    cat_id = (typeof cat_id === "string" || typeof cat_id === "number") ? cat_id : '';

    const sub_url = !cat_id || cat_id !== "subway" ? "food/list" : "custom_food/list";
    const config = {
        method: 'get',
        url: `/${sub_url}${cat_id ? '?cat_id=' + cat_id : ''}`,
    };

    return callCashBoxApi(config);
};

export const fetchCustomItemPreview = (option_ids) => {
    const config = {
        method: 'post',
        url: `/order/custom_order_preview`,
        data: {
            items: option_ids
        }
    };

    return callCashBoxApi(config);
};

export const fetchMenuItemInfo = (menuItemId, isCustomItem) => {
    const sub_url = isCustomItem ? "custom_food/details" : "food/details";

    const config = {
        method: 'get',
        url: `/${sub_url}${isCustomItem ? '?group_code=' + menuItemId : '?food_id=' + menuItemId}`,
    };

    return callCashBoxApi(config);
};


export const createNewOrder = (currentTableId) => {
    if (!currentTableId) {
        return;
    }

    const config = {
        method: 'post',
        url: `/order/create_order`,
        data: {
            table_id: currentTableId,
        }
    };

    return callCashBoxApi(config);
};

export const addToOrder = (currentOrderId, req_data_items, req_data_CustomItems) => {
    const config = {
        method: 'post',
        url: `/order/add_items_to_order`,
        data: {
            order_id: currentOrderId,
            ...req_data_items,
            ...req_data_CustomItems
        }
    };

    return callCashBoxApi(config);
};

export const cancelOrder = (currentOrderId) => {
    const config = {
        method: 'post',
        url: `/order/reject`,
        data: {
            order_id: currentOrderId
        }
    };

    return callCashBoxApi(config);
};

export const changeOrderTable = (orderId, tableId) => {
    const config = {
        method: 'post',
        url: `/order/change_table`,
        data: {
            order_id: orderId,
            table_id: tableId
        }
    };

    return callCashBoxApi(config);
};


export const editMenuItem = (new_data, isCustomItem) => {
    if (new_data === undefined || new_data === null) {
        return;
    }

    const sub_url = isCustomItem
        ? 'order/edit_item_of_custom_order'
        : 'order/edit_item_of_order';

    const config = {
        method: 'put',
        url: `/${sub_url}`,
        data: new_data
    };

    return callCashBoxApi(config);
};

export const deleteMenuItem = (id, currentOrderId, isCustomItem) => {
    if (id === undefined || id === null) {
        return;
    }

    const sub_url = isCustomItem
        ? "order/delete_custom_order"
        : "order/delete_item_from_order";

    const config = {
        method: 'delete',
        url: `/${sub_url}`,
        data: {
            order_id: currentOrderId,
            item_id: id
        }
    };

    return callCashBoxApi(config);
};


export const payBills = (req_data) => {
    const config = {
        method: 'post',
        url: `/order/checkout`,
        data: req_data
    };

    return callCashBoxApi(config);
};

export const sendTransactionToEmail = (checkoutId, email) => {
    const config = {
        method: 'post',
        url: '/order/send_checkout_email',
        data: {
            checkout_id: checkoutId,
            email: email
        }
    }

    return callCashBoxApi(config);
};


export const sendToKitchen = (req_data) => {
    const config = {
        method: 'post',
        url: '/order/send_to_kitchen',
        data: req_data
    }

    return callCashBoxApi(config);
};


export const changeDemoMode = (isDemoMode) => {
    const config = {
        method: 'put',
        url: `/setting/demo_mode`,
        data: {
            is_demo_mode: isDemoMode
        }
    };

    return callCashBoxApi(config);
};

export const orderDeliver = (currentOrderId) => {
    const config = {
        method: 'post',
        url: '/order/order_delivered',
        data: {
            order_id: currentOrderId
        }
    }

    return callCashBoxApi(config);
};


export const changeOrderMode = (orderId) => {
    const config = {
        method: 'post',
        url: `/order/convert_order`,
        data: {
            order_id: orderId
        }
    };

    return callCashBoxApi(config);
};


export const fetchOpenPayDetails = () => {
    const config = {
        method: 'get',
        url: "/order/open_pays",
    };

    return callCashBoxApi(config);
};


export const printcheckoutOnExternalDevice = (checkout_id) => {
    const config = {
        method: 'post',
        url: "/order/print_checkout_on_external_pay_devices",
        data: {
            checkout_id
        }
    };

    return callCashBoxApi(config);
};


// export const requestTypeIds = {
//     fetchOrdersList: "fetchOrdersList",
//     fetchAllFoodsList: "fetchAllFoodsList",
//     fetchMenuItemInfo: "fetchMenuItemInfo",
// };

