
export default [
    {
        id: 'tablesListShowStep',
        title: 'tables',
        titleId: 'tables',
        stepContentClassName: "tables-list-show-step",
    },
    {
        id: 'ordersListShowStep',
        title: 'orders',
        titleId: 'orders',
        stepContentClassName: "orders-list-show-step pt-2 mt-1",
    },
    {
        id: 'availableItemsShowStep',
        // title: 'foods',
        title: 'available foods',
        titleId: 'availableFoods',
        stepContentClassName: "available-items-list-show-step",
    },
    {
        id: 'itemDetailsPreviewStep',
        // title: 'food details',
        title: 'add food',
        titleId: 'addFood',
        stepContentClassName: "step-item-content edit-and-preview-box d-flex flex-column h-100 px-0 pb-3",
    }
];
