import { configureStore } from '@reduxjs/toolkit';
import appGeneralReducer from './slices/appGeneralSlice';
import stepManagementReducer from './slices/stepManagementSlice';
import paymentManagementReducer from './slices/paymentManagementSlice';
import transactionsReducer from './slices/transactionsSlice';


export const store = configureStore({
    reducer: {
        appGeneral: appGeneralReducer,
        stepManagement: stepManagementReducer,
        paymentManagement: paymentManagementReducer,
        transactions: transactionsReducer,
    },
})