import { Modal } from 'react-bootstrap';

const CustomModal = (props) => {
    const {
        onShow,
        onHide,
        children,
        ...otherProps
    } = props;

    const handleModalShow = () => {
        typeof onShow === "function" && onShow();

        const modals = document.querySelectorAll('.modal.show');
        const zIndex = 1040 + 10 * modals.length;

        modals.forEach((modal) => {
            modal.style.zIndex = zIndex;
        });

        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.style.zIndex = zIndex - 1;
            backdrop.classList.add('modal-stack');
        }
    };

    const handleModalHide = () => {
        typeof onHide === "function" && onHide();

        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.classList.remove('modal-stack');
        }
    };


    return (
        <Modal
            onShow={handleModalShow}
            onHide={handleModalHide}
            {...otherProps}
        >
            { children }
        </Modal>
    );
};

export default CustomModal;
